import './RecipePage.css'
import {Helmet} from 'react-helmet'
import Header from './Header/Header'
import Description from './Description/Description'
import Separator from './components/Separator'
import Ingredients from './Ingredients/Ingredients'
import Cookwares from './Cookwares/Cookwares'
import Instructions from './Instructions/Instructions'
import RecipeTags from './RecipeTags/RecipeTags'
import AuthorInfo from './AuthorInfo/AuthorInfo'
import Comments from './Comments/Comments'
import {useState} from 'react'
import { useEffect } from 'react'
import KangacookHeader from '../components/Header'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import { useParams} from 'react-router-dom'
import Cookies from 'js-cookie'
import Interaction from './components/Ineractions'
import {isAuthenticated} from '../UserManagement/hook/Authenticated'

import {NotFound, Restricted} from '../components/ErrorPage'
import Spinner from './Spinner' //动画组件

export default function RecipePage(){
	const [changeLang, setChangeLang] = useState(false)
	const cookie_value = Cookies.get('kangacook_user_id')
    const curr_username = (cookie_value && JSON.parse(cookie_value).username) || '';
	const { pk } = useParams(); // Get pk from the URL

	const [notFound, setNotFound] = useState(false)
	const [restricted, setRestricted] = useState(false)

	const [author, setAuthor] = useState(null)
	const [recipe, setRecipe] = useState(null);
	const [recipeIngredients, setRecipeIngredients] = useState(null);
	const [recipeCookwares, setRecipeCookwares] = useState(null);
	const [recipeInstructions, setRecipeInstructions] = useState(null);
	const [recipeTags, setRecipeTags] = useState(null);
	const [comments, setComments] = useState(
		{
			count: 0,
			next: `/comment/?recipe=${pk}`,
			results: [],
			history: [],
		}
	);

	const [collections, setCollections] = useState(null)
	const [toggleCollection, setToggleCollection] = useState(false)
	const [toggleLike, setToggleLike] = useState(false)
	const [toggleFetchComment, setToggleFetchComment] = useState(false)
	const [initing, setIniting] = useState(true)
	const [toggleComment, setToggleComment] = useState(false)

	const [InteractionSection, setInteractionSection] = useState(null)
	useEffect(() => {
		if(recipe){
			setInteractionSection(
				<Interaction
					{...recipe}
					collections={collections}
					toggleLike={toggleLike}
					setToggleLike={setToggleLike}
					toggleCollection={toggleCollection}
					setToggleCollection={setToggleCollection}
				/>
			)
		}
	}, [recipe])
	
	const fetchUserCollections = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			setCollections(data.filter(collection => collection.name !== 'Saved')); 
		} catch (error) {
			console.error('Error fetching user collection:', error);
		}
	}
	const fetchRecipeTags = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}/tags`,
				{
					method: 'GET',
					credentials: 'include',
				});
			if(response.status === 404){
				setNotFound(true)
				return
			}
			if(response.status === 403){
				setRestricted(true)
				return
			}
			const data = await response.json();

			setRecipeTags(data)
		} catch (error) {
			console.error('Error fetching recipe data:', error);
		}
	}
	const fetchRecipeInstructions = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}/instructions`,
				{
					method: 'GET',
					credentials: 'include',
				});
			if(response.status === 404){
				setNotFound(true)
				return
			}
			if(response.status === 403){
				setRestricted(true)
				return
			}
			const data = await response.json();

			setRecipeInstructions(data)
		} catch (error) {
			console.error('Error fetching recipe data:', error);
		}
	}
	const fetchRecipeCookwares = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}/cookwares`,
				{
					method: 'GET',
					credentials: 'include',
				});
			if(response.status === 404){
				setNotFound(true)
				return
			}
			if(response.status === 403){
				setRestricted(true)
				return
			}
			const data = await response.json();

			setRecipeCookwares(data)
		} catch (error) {
			console.error('Error fetching recipe data:', error);
		}
	}
	const fetchRecipeIngredients = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}/ingredients`,
				{
					method: 'GET',
					credentials: 'include',
				});
			if(response.status === 404){
				setNotFound(true)
				return
			}
			if(response.status === 403){
				setRestricted(true)
				return
			}
			const data = await response.json();

			setRecipeIngredients(data)
		} catch (error) {
			console.error('Error fetching recipe data:', error);
		}
	}
	const fetchRecipe = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}`,
				{
					method: 'GET',
					credentials: 'include',
				});
			if(response.status === 404){
				setNotFound(true)
				return
			}
			if(response.status === 403){
				setRestricted(true)
				return
			}
			const data = await response.json();
			setRecipe(data); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching recipe data:', error);
		}
	}
	const fetchComment = async () => {
		try{
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + comments.next,
				{
					method: 'GET',
				}
			)
			const data = await response.json();
			if(!comments.history.includes(data.next)){
				comments.history.push(data.next)
				comments.count = data.count
				comments.next = data.next
				comments.results = comments.results.concat(data.results)
				setComments(({...comments}))
			}
		} catch (error) {
			console.log(error)
			console.error('Error fetching recipe data:', error);
		}
	}
	
	useEffect(() => {
		if(initing){
			setIniting(false)
			return
		}
		comments.next && fetchComment()
	}, [toggleFetchComment])

	useEffect(() => {
		comments.next = `/comment/?recipe=${pk}`
		comments.results = []
		comments.history = []
		fetchComment()
	}, [toggleComment])

	useEffect(() => {
		if(cookie_value){
			fetchUserCollections()
			fetchRecipe()
		}
	}, [toggleCollection])
	useEffect(() => {
		if(cookie_value){
			fetchRecipe()
		}
		// fetchUserCollections()
	}, [toggleLike])

	useEffect(() => {
		const fetchFollowing = async (author) => {
			if (!isAuthenticated()){
				return
			}
			try{
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/user-network/${author}/is_following/?username=${curr_username}`
				)
				if(response.status === 200){
					setAuthor(a => ({...a, isFollowing:true}))
				}
				else{
					setAuthor(a => ({...a, isFollowing:false}))
				}
			}
			catch(error){
				console.log("THIS IS ERROR")
				console.error("ERROR FETCHING IF USER IS FOLLOWING. ", error)
			}
		}

		async function fetchAuthor() {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/recipe/${pk}/author`,
					{
						method: 'GET',
					});
				const data = await response.json();
				setAuthor(data)
				fetchFollowing(data.username)
			} catch (error) {
				console.error('Error fetching recipe data:', error);
			}
		}

		fetchAuthor()
		fetchRecipe();
		fetchRecipeIngredients();
		fetchRecipeInstructions();
		fetchRecipeCookwares();
		fetchRecipeTags();
	}, [pk, changeLang]);

	// 如果菜谱数据还没加载完，显示加载动画
    if (recipe === null && !notFound && !restricted) {
        return (
            <div className="loading-container">
                <Spinner /> {/* 显示加载动画 */}
            </div>
        );
    }

	const deleteComment = (index) => {
		comments.results.splice(index, 1)
		comments.count -= 1
		setComments(({...comments}))
	}

	return (
		<div className='recipe-container'>
			<Helmet>
                <meta property="og:title" content={recipe ? recipe.title : 'Kangacook...'} />
                <meta property="og:description" content={recipe ? recipe.description : 'This recipe does not have a description'} />
                <meta property="og:image" content={recipe ? recipe.image_url : 'default-image-url'} />
                <meta property="og:url" content={`https://kangacook.com/recipe/${pk}`} />
                <meta property="og:type" content="article" />

				<meta name="description" content={recipe ? recipe.description : 'This recipe does not have a description'} />
				<title> {recipe && author? `${recipe.title} Recipe - by ${author.username} | Kangacook` : "Kangacook Recipe | Kangacook"} </title>
				<link rel="canonical" href={`/recipe/${pk}`} />
            </Helmet>
			<KangacookHeader changeLang={changeLang} setChangeLang={setChangeLang} />
			<div className='recipe-container-body'>
				<div className='recipe-container-nav-bar'> Navigation </div>
				<NavigationBar />
				
				{
				(restricted && <Restricted />) ||
				(notFound && <NotFound />) || 
				<div className='recipe-container-main-content'>
					{/* <Header />
					<Separator />
					<Description />
					<Ingredients />
					<Cookwares />
					<Instructions />
					<RecipeTags />
					<AuthorInfo />
					<Separator />
					<Comments />
					 */}


					<Header
						{...recipe}
						ingredient_count={recipeIngredients? recipeIngredients.length : 0}
						collections={collections}
						toggleLike={toggleLike}
						setToggleLike={setToggleLike}
						toggleCollection={toggleCollection}
						setToggleCollection={setToggleCollection}
						interactionSection = {InteractionSection}
						author={author? author.username : 'UNKNOWN'}
					/>
					<Separator />
					<Description details={recipe.description} />
					<Ingredients 
						recipe_id = {pk}
						serving_count={recipe.servings}
						recipe_ingredients={recipeIngredients}
					/>

					{
					recipeCookwares &&
					<Cookwares
						cookwares={
							recipeCookwares.map(cookware => `${cookware.quantity} ${cookware.name}`)
						}
					/>
					}
					{
					recipeInstructions && 
					<Instructions instructions={recipeInstructions} />
					}
					{
					recipeTags && 
					<RecipeTags tags={recipeTags} />
					}
					<AuthorInfo {...author} curr_username={curr_username}/> 
					{
						InteractionSection
					}
					<Separator />
					<Comments 
						comments={comments.results}
						deleteComment={deleteComment}
						comments_count = {comments.count}
						recipe_id={recipe.recipe_id}
						toggleFetchComment={toggleFetchComment}
						setToggleFetchComment={setToggleFetchComment}
						toggleComment={toggleComment}
						setToggleComment={setToggleComment}
					/>
				</div>
				}
			</div>
		</div>
	)
}
