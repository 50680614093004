import { useState, useEffect } from "react";
import EarnPointsGuide from "../EarnPointsGuide/EarnPointsGuide";
import RedeemPointsGuide from "../RedeemPointsGuide/RedeemPointsGuide";
import styles from "./EarnRedeem.module.css";
import TabFrame from "../../../components/Tab/TabFrame";
import TabButton from "../../../components/Tab/TabButton";
import {isAuthenticated} from "../../../UserManagement/hook/Authenticated"
import { useTranslation } from "react-i18next";

const EarnRedeem = ({ currentPoints, checkinToggle, setCheckinToggle}) => {
  const { t } = useTranslation();
	const [isCheckedIn, setIsCheckedIn] = useState(false)
  const [tabIndex, setTabIndex] = useState(0);

  const handleClick = (tab) => {
    setTabIndex(tab);
  };

  const getTabClasses = (index) => {
    return tabIndex === index;
  };

	useEffect(() => {
		const checkSignedInState = async () => {
			if (!isAuthenticated()) {
				console.log("YOU NEED TO LOGIN FIRST");
				return;
			}
			try {
				const apiHost = process.env.REACT_APP_BACKEND_URL;
				const response = await fetch(`${apiHost}/reward/sign-in-check`, {
					method: "GET",
					credentials: "include",
				});
				if (response.ok) {
					const data = await response.json();
					setIsCheckedIn(data["signed-in"])
				}
			} catch (error) {
				console.error("Error checking in:", error);
			}

			return;
		};
		checkSignedInState()
	}, [checkinToggle])

  return (
    <div className={styles["earn-redeem-container"]}>
      <TabFrame>
        <TabButton
          onClick={() => handleClick(0)}
          tabName={t("reward.earn")}
          isCurrent={getTabClasses(0)}
        />
        <TabButton
          onClick={() => handleClick(1)}
          tabName={t("reward.redeem")}
          isCurrent={getTabClasses(1)}
        />
      </TabFrame>
      <div style={{display: tabIndex === 0 ? "block" : "none"}}>
        <EarnPointsGuide checkinToggle={checkinToggle} setCheckinToggle={setCheckinToggle} isCheckedIn={isCheckedIn} />
      </div>
      <div style={{display: tabIndex === 1 ? "block" : "none"}}>
        <RedeemPointsGuide currentPoints={currentPoints} />
      </div>
    </div>
  );
};

export default EarnRedeem;
