import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import "./Cart.css";
import DishList from "./components/DishList";
import StoreList from "./components/StoreList";
import ItemList from "./components/ItemList";
import Checkout from "./components/Checkout";
import axios from 'axios';

export default function Cart() {
  const [selectedStore, setSelectedStore] = useState("fudiAsianMart");
  const [dishes, setDishes] = useState(false);
	const removeDish = (recipe_id) => {
		let newDishes = dishes.filter(d => d.recipe_id !== recipe_id)
		setDishes(newDishes)
		if(newDishes.length !== 0){
			setSelectedRecipe(dishes[0].recipe_id)
		}
		setFudiItems(item => item.filter(i => i.recipe_id !== recipe_id))
		setWalmartItems(item => item.filter(i => i.recipe_id !== recipe_id))
	}
  const fetchDishes = async () => {
    try {
      const apiHost = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${apiHost}/user-profile/shopping-cart`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      let result_data = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].recipe_id !== 10) {
          result_data.push(data[i]);
        }
      }
      setDishes(result_data);
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  const [allCartItems, setAllCartItems] = useState([]);
  const [walmartItems, setWalmartItems] = useState([]);
  const [fudiItems, setFudiItems] = useState([]);
  const [fetchedWalmart, setFetchedWalmart] = useState(false);
  const [fetchedFudi, setFetchedFudi] = useState(false);
  const [currCartItems, setCurrCartItems] = useState([]);
  const [listRecipes, setListRecipes] = useState(false);
	const [selectedRecipe, setSelectedRecipe] = useState(-1);

  const handleDelete = (ingredient) => {
		const ingredient_id = ingredient.ingredient_id
    const updatedFudiItems = fudiItems.filter(item => item.ingredient_id !== ingredient_id);
    setFudiItems(updatedFudiItems);
    const updatedWalmartItems = walmartItems.filter(item => item.ingredient_id !== ingredient_id);
    setWalmartItems(updatedWalmartItems);
    if (listRecipes && selectedRecipe !== -1) {
      const remainingItems = updatedFudiItems.filter(item => item.recipe_id === selectedRecipe);
      if (remainingItems.length === 0) {
				setListRecipes(false)
				const recipe_id = ingredient.recipe_id
				let newDishes = dishes.filter(d => d.recipe_id !== recipe_id)
				setDishes(newDishes)
				if(newDishes.length !== 0){
					setSelectedRecipe(dishes[0].recipe_id)
				}
      } 
    } 
  };

	const fetchCartItems = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/`, { withCredentials: true });
			const fudiData = response.data
			for(let i = 0; i < fudiData.length; i++){
				fudiData[i]['fudiAsianMart'] = false
			}
			const walmartData = response.data
			for(let i = 0; i < walmartData.length; i++){
				walmartData[i]['walmart'] = false
			}
			setFudiItems(fudiData);
			setWalmartItems(walmartData);
			setFetchedWalmart(false)
			setFetchedFudi(false)
		} catch (error) {
			console.error('Error fetching cart items:', error);
		}
	};

	useEffect(() => {
		if(selectedStore === "fudiAsianMart"){
			setAllCartItems(fudiItems)
		}
		else{
			setAllCartItems(walmartItems)
		}
	}, [selectedStore, fudiItems, walmartItems])

	useEffect(() => {
		const fetchFudiCartItem = async (ingredient_id, cartIdx) => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/${ingredient_id}/get-fudi-ingredient`, { withCredentials: true });
				setFudiItems(old=>
					[...old.map((item, index) => 
						index === cartIdx? 
							{...item, ...response.data, 'fudiAsianMart': true} : 
							item
					)]
				)
			} catch (error) {
				console.error('Error fetching cart items:', error);
			}
		};
		if(allCartItems.length > 0 && !fetchedFudi){
			setFetchedFudi(true)
			for(let i = 0; i < allCartItems.length; i++){
				fetchFudiCartItem(allCartItems[i].ingredient_id, i)
			}
		}

		const fetchWalmartCartItem = async (ingredient_id, cartIdx) => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/${ingredient_id}/get-walmart-ingredient`, { withCredentials: true });
				setWalmartItems(old=>
					[...old.map((item, index) => 
						index === cartIdx? 
							{...item, ...response.data, 'walmart': true} : 
							item
					)]
				)
			} catch (error) {
				console.error('Error fetching cart items:', error);
			}
		};
		if(allCartItems.length > 0 && !fetchedWalmart){
			setFetchedWalmart(true)
			for(let i = 0; i < allCartItems.length; i++){
				fetchWalmartCartItem(allCartItems[i].ingredient_id, i)
			}
		}

	}, [allCartItems, fetchedWalmart, fetchedFudi])

	useEffect(() => {
		if(allCartItems && allCartItems.length > 0){
			setCurrCartItems(allCartItems);
		}
		if(dishes.length > 0 && selectedRecipe === -1){
			setSelectedRecipe(dishes[0].recipe_id)
		}
	}, [allCartItems, dishes, selectedRecipe])


	useEffect(() => {
		if(listRecipes && selectedRecipe !== -1){
			setCurrCartItems(
				allCartItems.filter((item) => item.recipe_id === selectedRecipe)
			)
		}
		if(!listRecipes){
			setCurrCartItems(allCartItems)
		}
	}, [listRecipes, selectedRecipe, allCartItems])

  useEffect(() => {
    fetchCartItems();
    fetchDishes();
  }, []);
  return (
    <div className="cart-page">
      <Helmet>
        <meta property="og:title" content="Kangacook - Shopping Cart" />
        <meta
          property="og:description"
          content="Kangacook shopping cart - buy all your recipe ingredients in a single click"
        />
        <meta
          property="og:url"
          content="https://kangacook.com/shopping-cart/"
        />
        <meta property="og:type" content="website" />

        <meta
          name="description"
          content="Kangacook shopping cart - buy all your recipe ingredients in a single click"
        />
        <title> Kangacook - Shopping Cart </title>
				<link rel="canonical" href="https://kangacook.com/shopping-cart" />
      </Helmet>
      <Header />
      <NavigationBar />
      <div className="cart">
        <DishList dishes={dishes} removeDish = {removeDish} />
        <StoreList
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
        <ItemList
					recipes={dishes} listRecipes={listRecipes} setListRecipes={setListRecipes}
					selectedRecipe={selectedRecipe}
					setSelectedRecipe={setSelectedRecipe}
					currCartItems={currCartItems}
					handleDelete={handleDelete}
					selectedStore={selectedStore}
				/>
        <Checkout selectedStore={selectedStore} />
      </div>
    </div>
  );
}
