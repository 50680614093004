import React from 'react'
import Header from '../components/Header'
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './RewardRedeemed.css';
import '../main.css';
import { RecipeCardDesktop, RecipeCardMobile } from "./image";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RewardRedeemed() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <>
            <Header/>
            <NavigationBar/>
            <div className='container-wrapper'>
                <div className='reward-redeemed-page'>
                    <img src={RecipeCardDesktop} alt='recipe-card' className='image desktop-image' />
                    <img src={RecipeCardMobile} alt='recipe-card' className='image mobile-image' />
                    <div className='reward-redeemed-container'>
                        <div className='redeemed-title-container'>
                            <h2 className='redeemed-title'>{t("rewardRedeemed.yourPointsWereRedeemed")}</h2>
                        </div>
                        <div className='redeemed-info-container'>
                            <p>{t("rewardRedeemed.wellShipYourOrderAfterConfirmatiom")}</p>
                            <p>{t("rewardRedeemed.statusWillBeEmailedIfFailsPointsWillBeRefunded")}</p>
                            <p>{t("rewardRedeemed.expectShippingWithin5BusinessDays")}</p>
                        </div>
                        <div className='redeemed-button-container'>
                            <button
                                className='redeemed-button'
                                onClick={() => navigate("/reward")}
                            >
                                {t("rewardRedeemed.backToRewardPage")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
