import './UpdateProfile.css'
import kangacook_avatar from '../../icons/kangacook_avatar.png'
import cam_img from '../../icons/cam.svg'
import reupload_img from '../../icons/reupload.svg'
import trash_img from '../../icons/trash.svg'
import upload_img from '../../icons/upload.svg'
import {useState, useRef, useEffect} from 'react'
import '../../../../../components/components.css'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function UpdateProfile({
	setUpdatingProfile, userInfo,
	toggleUser, setToggleUser,
}){
	const date_today = new Date().toLocaleDateString('fr-ca')
	const min_date = '1900-01-01'

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [birthday, setBirthday] = useState('yyyy-MM-dd')
	const [aboutMe, setAboutMe] = useState('')

	const [uploadingPhoto, setUploadingPhoto] = useState(false)
	const [uploadedPhoto, setUploadedPhoto] = useState(null)
	const [uploadedBlob, setUploadedBlob] = useState(null)

	const fileUploadRef = useRef(null);
	const { t } = useTranslation();
	const navigate = useNavigate()

	useEffect(() => {
		if(userInfo){
			userInfo.first_name && setFirstName(userInfo.first_name)
			userInfo.last_name && setLastName(userInfo.last_name)
			userInfo.username && setUsername(userInfo.username)

			if(userInfo.birthday){
				const date = new Date(userInfo.birthday);
				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
				const day = String(date.getUTCDate()).padStart(2, '0');
				const bday = `${year}-${month}-${day}`;
				console.log("COMPUTED DATE: ", bday);

				setBirthday(bday);

			}
			userInfo.bio && setAboutMe(userInfo.bio)
			setEmail(userInfo.email)
		}
	}, [userInfo])
	
	const updateUploadedPhoto = (input) => {
		if (input.target.files && input.target.files[0]) {
			// TODO: 
			// 	Change restriction // or not
			if(input.target.files[0].size > 10000000){
				console.log("FILE TOO LARGE")
			}

			const image = new Image();
			image.onload = function(){
				if(this.width > 1000 || this.height > 1000){
					console.log("image dimension out of bound")
				}
				setUploadedPhoto(image.src)
				setUploadedBlob(input.target.files[0])
				console.log(this.width, this.height);
			}

			image.src = URL.createObjectURL(input.target.files[0])

		}
	}

	async function submitForm(event) {
		event.preventDefault();
		console.log("Submitting form")
		const formData = new FormData();
		formData.append('username', username)
		formData.append('first_name', firstName)
		formData.append('last_name', lastName)
		formData.append('bio', aboutMe)
		if(birthday !== 'yyyy-MM-dd'){
			formData.append('birthday', birthday)
		}
		if(uploadedPhoto){
			formData.append('profile_picture', uploadedBlob)
		}
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${userInfo.username}/`,{
				method: 'PUT',
				credentials: 'include',
				body: formData,
			});
			console.log(response)
			const cookie_value = Cookies.get('kangacook_user_id')
			const json_cookie = JSON.parse(cookie_value)
			json_cookie.username = username
			Cookies.set("kangacook_user_id", JSON.stringify(json_cookie), {
				expires: 90,
				domain: process.env.REACT_APP_COOKIE_URL,
			});
			  
			// navigate(`/profile/${username.toLowerCase()}`);
			navigate(`/profile/${username}`);
			// to reload the cookies, so that the nav bar can update the latest URL
			navigate(0);
			setToggleUser(!toggleUser)
		} catch (error) {
			console.error('Error fetching user information:', error);
		}

		userInfo.username = username
		setUpdatingProfile(false)
	}

	return (
		<div className='user-profile-update-profile'>
			{
				uploadingPhoto && 
				<div className='user-profile-upload-photo-card'>
					<p className='user-profile-upload-photo-header'> {t("updateProfile.choosePhoto")} </p>
					<div className='user-profile-upload-photo-options'>
						<img 
							src={reupload_img} 
							className='user-profile-upload-photo-option-icon' 
							onClick={()=>fileUploadRef.current.click()}
							alt='reupload-icon'
						/>
						<img 
							src={trash_img} 
							className='user-profile-upload-photo-option-icon' 
							onClick={()=>setUploadedPhoto(null)}
							alt='delete-icon'
						/>
					</div>
					{
						uploadedPhoto && 
						<div className='user-profile-upload-photo-main'>
							<img src={uploadedPhoto || userInfo.profile_picture} className='user-profile-uploaded-photo' alt='profile-picture'/>
						</div>
					}
					<div 
						className={
							(uploadedPhoto && 'hidden') ||
							'user-profile-upload-photo-main'
						}
					>

						<img src={upload_img} className='user-profile-upload-photo-option-icon' alt='upload-icon'/>
						<div className='user-profile-upload-photo-description'>
							<p>
								{t("updateProfile.uploadYourImage")}
							</p>
							<p>
								{t("updateProfile.restrictedFormats")}
							</p>
						</div>
						<label
							htmlFor='user-profile-upload-photo'
							className='secondary-text-wrapper'
							id='user-profile-upload-photo-button-label'
						>
							<p
								className='secondary-text'
							>
								{t("updateProfile.browse")}
							</p>
						</label>
						<input 
							type='file'
							accept=".jpg,.jpeg,.png"
							id='user-profile-upload-photo'
							className='hidden'
							ref={fileUploadRef}
							onChange={updateUploadedPhoto}
						/>
					</div>
					<div className='user-profile-form-submission'>
						<div
							onClick={() => {setUploadedPhoto(null); setUploadingPhoto(false)}}
							className='secondary-text-wrapper' 
						>
							<p 
								className='secondary-text'
							>
								{t("updateProfile.cancel")}
							</p>
						</div>
						<div className='secondary-prime-wrapper'>
							<input
								className='secondary-prime-text'
								type='submit'
								value={t("updateProfile.submit")}
								onClick={() => setUploadingPhoto(false)}
							/>
						</div>
					</div>
				</div>
			}
			<div 
				className={
					uploadingPhoto? 
					'user-profile-update-profile-card hidden':
					'user-profile-update-profile-card'
				}
			>
				<div className='user-profile-update-profile-card-header'>
					<p> {t("updateProfile.editProfile")} </p>
					<div 
						className='xx-large-avatar-container'
						onClick={()=>setUploadingPhoto(true)}
					>
						<span className='xx-large-avatar-cover' />
						{
							(uploadedPhoto && <img src={uploadedPhoto} className='xx-large-avatar-avatar' alt='profile-picture'/>) || 
							(
								userInfo.profile_picture && 
								<img src={uploadedPhoto || userInfo.profile_picture} className='xx-large-avatar-avatar' alt='profile-picture'/>
							)
							|| 
							<img src={kangacook_avatar} className='xx-large-avatar-default' alt='profile-picture'/>
						}
						
						<img src={cam_img} className='xx-large-avatar-cam' alt='camera-icon'/>
					</div>
				</div>
				
				<form onSubmit={submitForm}>
					<div className='user-profile-form-name-container'>
						<div className='user-profile-form-input-container'>
							<p className='user-profile-form-text'>
								{t("updateProfile.firstname")}
							</p>
							<input 
								className='user-profile-form-input'
								name='firstName'
								placeholder={t("updateProfile.firstname")}
								value={firstName}
								maxLength='20'
								onChange={(e)=>setFirstName(e.target.value)}
							/>
						</div>
						<div className='user-profile-form-input-container'>
							<p className='user-profile-form-text'>
								{t("updateProfile.lastname")}
							</p>
							<input 
								className='user-profile-form-input'
								name='lastName'
								placeholder={t("updateProfile.lastname")}
								maxLength='20'
								value={lastName}
								onChange={(e)=>setLastName(e.target.value)}
							/>
						</div>
					</div>

					<div className='user-profile-form-input-container'>
						<p className='user-profile-form-text'>
							{t("updateProfile.username")}
						</p>
						<input 
							className='user-profile-form-input'
							name='username'
							placeholder={t("updateProfile.username")}
							maxLength='20'
							value={username}
							onChange={(e)=>setUsername(e.target.value)}
						/>
					</div>

					<div className='user-profile-form-input-container'>
						<p className='user-profile-form-text'>
							{t("updateProfile.email")}
						</p>
						<input 
							className='user-profile-form-input user-profile-form-readonly'
							name='email'
							placeholder={t("updateProfile.noEmail")}
							value={email}
							readOnly='readonly'
						/>
					</div>

					<div className='user-profile-form-input-container'>
						<p className='user-profile-form-text'>
							{t("updateProfile.birthday")}
						</p>
						<input 
							className='user-profile-form-input user-profile-form-input-birthday'
							name='birthday'
							type='date'
							placeholder={t("updateProfile.date")}
							min={min_date}
							max={date_today}
							value={birthday}
							onChange={(e)=>{e.target.value < date_today && e.target.value < date_today && setBirthday(e.target.value)}}
						/>
					</div>

					<div className='user-profile-form-input-container'>
						<p
						 	className='user-profile-form-text'
						>
							{t("updateProfile.aboutMe")}
						</p>
						<textarea 
							className='user-profile-form-textarea'
							name='aboutMe'
							placeholder={t("updateProfile.enterContent")}
							maxLength='500'
							value={aboutMe}
							onChange={(e)=>setAboutMe(e.target.value)}
						/>
					</div>

					<div className='user-profile-form-submission'>
						<div
							className='secondary-text-wrapper' 
							onClick={() => setUpdatingProfile(false)}
						>
							<p 
								className='secondary-text'
							>
								{t("updateProfile.cancel")}
							</p>
						</div>
						<input
							id='user-profile-form-submit-button'
							className='hidden'
							type='submit'
							value='Submit'/>
						<label
							htmlFor='user-profile-form-submit-button'
							className='secondary-prime-wrapper'
						>
							<p
							className='secondary-prime-text'
							>{t("updateProfile.submit")}</p>
						</label>
					</div>

				</form>
			</div>
		</div>
	)
}
