import save_img from './icons/saved.svg'
import saved_img from './icons/bookmark-fill.svg'
import check_img from './icons/check.svg'
import plus_img from './icons/plus.svg'
import CreateCollection from './CreateCollection'
import {useState, useRef, useEffect} from 'react'
import './components.css'
import './SavedComponent.css'
import { useNavigate } from 'react-router-dom'
import {isAuthenticated} from '../UserManagement/hook/Authenticated'
import { useTranslation } from "react-i18next";

export default function SavedComponent(props){
	const navigate = useNavigate();
	const { t } = useTranslation();
	const DEFAULT_COLLECTION_NAME = 'Saved'
	const savedRef = useRef(null)
	const cardRef = useRef(null)
	const [saveCount, setSaveCount] = useState(props.saved_count)
	const [saved, setSaved] = useState(false)
	const [open, setOpen] = useState(false)
	const [collectionState, setCollectionState] = useState([])
	useEffect(() => {
		setSaveCount(props.saved_count)
	}, [props.saved_count])

	useEffect(() => {
		setSaved(props.saved)
	}, [props.saved])

	// Handle direct save/unsave when only default collection exists
	const handleDirectSave = async () => {
		if (saved) {
			// Remove from default collection
			removeFromCollection(DEFAULT_COLLECTION_NAME)
			setSaved(false)
			setSaveCount(saveCount - 1)
		} else {
			// Add to default collection
			addToCollection(DEFAULT_COLLECTION_NAME)
			setSaved(true)
			setSaveCount(saveCount + 1)
		}
	}

	const saveCollection = async (index) => {
		// Special handling for "Saved" collection (index -1)
		if (index === -1) {
			if (saved) {
				// Remove from all collections
				removeFromCollection(DEFAULT_COLLECTION_NAME)
				setSaved(false)
				setSaveCount(saveCount - 1)
				// Reset all collection states to false
				setCollectionState([...Array(props.collections.length)].map(x=>false))
			} else {
				// Add to default collection
				addToCollection(DEFAULT_COLLECTION_NAME)
				setSaved(true)
				setSaveCount(saveCount + 1)
			}
			return
		}

		// For other collections
		if(!collectionState[index]){
			collectionState[index] = true
			setCollectionState([...collectionState])
			addToCollection(props.collections[index].name)
			
			// If adding to first collection and not already saved, also add to Saved
			if(!saved){
				setSaved(true)
				setSaveCount(saveCount + 1)
				addToCollection(DEFAULT_COLLECTION_NAME)
			}
		}
		else{
			collectionState[index] = false
			setCollectionState([...collectionState])
			removeFromCollection(props.collections[index].name)
			
			// Removed automatic removal from Saved when removing from all collections
			// The Saved collection now works independently
		}
	}
	
	useEffect(() => {
		if(props.collections && !open){
			setCollectionState(
				[...Array(props.collections.length)].map(x=>false)
			)
		}
	}, [props.collections])

	const [creating, setCreating] = useState(false)


	const addToCollection = async (collection_name) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/collection-content/`,{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					recipe_id: props.recipe_id,
					collection_name: collection_name,
				}),
				credentials: 'include',
			});
			console.log(response)
		} catch (error) {
			console.error('Error unfollowing user', error);
		}
		props.setToggleCollection(!props.toggleCollection)
	}

	const removeFromCollection = async (collection_name) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/collection-content/${collection_name}`,{
				method: 'DELETE',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					recipe_id: props.recipe_id,
				})
			});
			console.log(response)
		} catch (error) {
			console.error('Error unfollowing user', error);
		}
		props.setToggleCollection(!props.toggleCollection)
	}

	const fetchSavedCollections = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection/${props.recipe_id}/list-saved-list`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			
			if (props.collections) {
				const newCollectionState = props.collections.map(collection => 
					data.saved_to.includes(collection.name)
				);
				setCollectionState(newCollectionState);
			}
		} catch (error) {
			console.error('Error fetching saved collections:', error);
		}
	}

	const toggleSave = async (e) => {
		if(!isAuthenticated()){
			navigate('/login')
			return
		}
		
		// If no collections or only default collection, handle direct save/unsave
		if(!props.collections || props.collections.length === 0){
			handleDirectSave()
			return
		}
		
		if (!open) {
			await fetchSavedCollections();
		}
		
		setOpen(!open)
	}

	useEffect(() => {
		const handleSavedClick = (e) => {
			if(cardRef.current
					&& !cardRef.current.contains(e.target)
					&& !savedRef.current.contains(e.target)
					&& !creating){
				setOpen(false)
			}
		}
		open && document.addEventListener('click', handleSavedClick)
		return () => {
			open && document.removeEventListener('click', handleSavedClick)
		}
	}, [open])

	return (
		<div
			className='components-saved-recipe'
			ref = {savedRef}
			onClick={(e)=>e.stopPropagation()}
		>
			<div
				onClick={toggleSave}
				className='chip-container'
			>
				<img src={saved? saved_img : save_img} className='chip-icon' alt='save-icon'/>
				<p className='chip-stat'>{saveCount}</p>

			</div>


			{open &&
				<>
			<div onClick={()=>setOpen(false)} className='components-saved-background components-saved-mb' />
			<div
				className='components-saved-card components-saved-mb'
				ref = {cardRef}
			>
				<div className='components-saved-header'>
					<p> {t("savedComponent.saveRecipe")} </p>
				</div>
				<div className='components-saved-separator' />
				<div 
					className='components-saved-item'
					onClick={()=>saveCollection(-1)}
				>
					{
						saved?
						<div
							className='components-saved-checkbox-active' 
						>
							<img src={check_img} alt='check-icon'/>
						</div>
						:
						<div
							className='components-saved-checkbox' 
						/>
					}
					<p> {t("savedComponent.saved")} </p>
				</div>
				{
					props.collections && 
					props.collections.map((item, index) => {
						return (
						<div 
							key={index}
							onClick={()=>saveCollection(index)}
							className='components-saved-item'
						>
							{
								collectionState[index]?
								<div
									className='components-saved-checkbox-active' 
								>
									<img src={check_img} alt='check-icon'/>
								</div>
								:
								<div
									className='components-saved-checkbox' 
								/>
							}
							<p> {item.name} </p>
						</div>
						)
					})
				}
				<div 
					className='components-saved-item'
					onClick={() => setCreating(true)}
				>
					<img src={plus_img} alt='add-icon'/>
					<p> {t("savedComponent.createCollection")} </p>
				</div>
			</div>







			<div
				className='components-saved-card components-saved-dt'
				ref = {cardRef}
			>
				<div 
					className='components-saved-item'
					onClick={()=>saveCollection(-1)}
				>
					{
						saved?
						<div
							className='components-saved-checkbox-active' 
						>
							<img src={check_img} alt='check-icon'/>
						</div>
						:
						<div
							className='components-saved-checkbox' 
						/>
					}
					<p> Saved </p>
				</div>
				{
					props.collections && 
					props.collections.length !== 0 && 
					<div className='components-saved-separator' />
				}
				{
					props.collections && 
					props.collections.map((item, index) => {
						return (
						<div 
							key={index}
							onClick={()=>saveCollection(index)}
							className='components-saved-item'
						>
							{
								collectionState[index]?
								<div
									className='components-saved-checkbox-active' 
								>
									<img src={check_img} alt='check-icon'/>
								</div>
								:
								<div
									className='components-saved-checkbox' 
								/>
							}
							<p> {item.name} </p>
						</div>
						)
					})
				}
				<div className='components-saved-separator' />
				<div 
					className='components-saved-item'
					onClick={() => setCreating(true)}
				>
					<img src={plus_img} alt='add-icon'/>
					<p> {t("savedComponent.createCollection")} </p>
				</div>
			</div>
			</>}
			{
				creating &&
				<CreateCollection
					toggleCollection={props.toggleCollection}
					setToggleCollection={props.setToggleCollection}
					setCreating={setCreating}
				/>
			}
		</div>
	)
}

