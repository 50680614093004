import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../hook/awsAuthServices';
import ReCAPTCHA from 'react-google-recaptcha';
import { Input, Checkbox } from 'antd';
import { useTranslation } from "react-i18next";
import { useLocation} from 'react-router-dom'

export default function KangaSignup({ setErrorMessage }) {
	const loc = useLocation();
	const queryParams = new URLSearchParams(loc.search)
	const invitor = queryParams.get('invitor') || ""

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validUsername, setValidUsername] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const { t } = useTranslation();

  // 新增的验证条件状态
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    hasLowerCase: false,
    minLength: false,
  });

  // Add this at the top with other state declarations
  const [showUsernameError, setShowUsernameError] = useState(false);

  // 验证密码的逻辑
  const validatePassword = (value) => {
    const criteria = {
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      minLength: value.length >= 8,
    };
    setPasswordCriteria(criteria);
    setValidPassword(Object.values(criteria).every(Boolean));
  };

  const validateUsername = (username) => {
    const validUsernameRegex = /^[A-Za-z0-9_]+$/;
    return validUsernameRegex.test(username);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate username first
    if (!username.trim() || !validateUsername(username)) {
      setValidUsername(false);
      setShowUsernameError(true);
      return;
    }

    if (!email.trim()) {
      setValidEmail(false);
      return;
    }

    if (!validPassword) {
      return;
    }

    if (!agreeToTerms) {
      setErrorMessage("You must agree to the terms and conditions!");
      return;
    }

    try {
      await signUp(password, email, username, invitor);
      navigate(invitor? `/confirm/?invitor=${invitor}&username=${username}` : '/confirm', { state: { email } });
    } catch (error) {
      console.log("Signup error:", error);
      
      // Updated error handling logic
      if (error.name === 'UsernameExistsException' || error.code === 'UsernameExistsException') {
        setValidEmail(false);
        setEmailErrorMessage(t("kangaSignUp.emailAlreadyExist"));
      } 
      // Check for invalid email format from Cognito
      else if (
        error.name === 'InvalidParameterException' || 
        error.code === 'InvalidParameterException' ||
        (error.message && error.message.toLowerCase().includes('email'))
      ) {
        // Handle Cognito email validation failure
        setValidEmail(false);
        setEmailErrorMessage(t("kangaSignUp.emailErrorMessage"));
      } 
      else {
        setErrorMessage(`Sign up failed: ${error.message}`);
      }
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit} noValidate>
      <div className="input-container">
        <div className='input-username'>
          <label className='username-text'>{t("kangaSignUp.username")}</label>
          <input 
            type="text" 
            placeholder="Username" 
            className={`input-box ${!validUsername ? 'input-error' : ''}`}
            value={username}
            maxLength='20'
            onChange={(e) => {
              setUsername(e.target.value);
              setValidUsername(validateUsername(e.target.value));
            }}
            required
          />
        </div>
        {!validUsername && (
          <div className="validation-error">
            <img 
              src="/stroke.png" 
              alt="Error" 
              className="error-icon"
            />
            {t("kangaSignUp.usernameSpecialCharError")}
          </div>
        )}
      </div>

      <div className="input-container">
        <div className='signup-input-email'>{t("kangaSignUp.email")}</div>
        <input 
          type="email" 
          placeholder="Email" 
          className={`signup-email-input ${!validEmail ? 'input-error' : ''}`}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setValidEmail(emailRegex.test(e.target.value));
            setEmailErrorMessage(t("kangaSignUp.emailErrorMessage"));
          }}
          required
          noValidate
        />
        {!validEmail && (
          <div className="validation-error">
            <img 
              src="/stroke.png" 
              alt="Error" 
              className="error-icon"
            />
            {emailErrorMessage || t("kangaSignUp.emailErrorMessage")}
          </div>
        )}
      </div>

      <div className='signup-input-password'>{t("kangaSignUp.password")}</div>
        <Input.Password 
          placeholder="Password minimum length 8 character(s)" 
          className={`signup-password-input ${!validPassword ? 'input-error' : ''}`}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
          required
          style={{ paddingLeft: '16px' }}
        />
      
        {/* 密码条件列表 */}
        <ul className="password-criteria-list">
          <li style={{ color: passwordCriteria.hasNumber ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainNumber")}
          </li>
          <li style={{ color: passwordCriteria.hasSpecialChar ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainSpecialChar")}
          </li>
          <li style={{ color: passwordCriteria.hasUpperCase ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainUpperCase")}
          </li>
          <li style={{ color: passwordCriteria.hasLowerCase ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainLowerCase")}
          </li>
          <li style={{ color: passwordCriteria.minLength ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContain8Characters")}
          </li>
        </ul>

      <div className='agreement'>
        <Checkbox
          checked={agreeToTerms}
          onChange={(e) => setAgreeToTerms(e.target.checked)}
        >
          {t("kangaSignUp.agreement")}
        </Checkbox>
      </div>

      <div className='verification'>
        <ReCAPTCHA
          sitekey='6LcCAygqAAAAAHheHcFQ54P_RAtWUpU-nlJj29Sk'
          onChange={onCaptchaChange}
        />
      </div>

      <button type="submit" className="signup-button"
				disabled={
					!captchaValue || !agreeToTerms ||
					!username || !email || !password
				}
			>
        {t("kangaSignUp.createAccount")}
      </button>

    </form>
  );
}
