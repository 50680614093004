import React from "react";
import styles from "./PointsHistory.module.css";

import { ScrollContext } from "../../ScrollContext";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const PointsHistory = ({checkinToggle}) => {
  const { t } = useTranslation();
  const scrollCtx = useContext(ScrollContext);
  const { targetRef } = scrollCtx;

  const [currPage, setCurrPage] = useState(0);
  const [rewardHistoryData, setRewardHistoryData] = useState([])
  const [rewardHistory, setRewardHistory] = useState({next: `${process.env.REACT_APP_BACKEND_URL}/reward`, results: []});
  const prevPage = () => {
    if (currPage > 0) {
      setCurrPage(currPage - 1);
    }
  };
  const nextPage = () => {
    if ((currPage + 1) * 10 < rewardHistoryData.length) {
      setCurrPage(currPage + 1);
    }
  };
	const formatFetchResult = (data) => {
		let result = []
		for(let i = 0; i < data.length; i++){
			const date = new Date(data[i].time)
			let curr_status = "Approved";
			if(data[i].status === 0){
				curr_status="Declined"
			}
			else if(data[i].status === 1){
				curr_status = "Reviewing"
			}
			let pointText;
			let styleClass = 'increase';
			if(data[i].point > 0){
				pointText = '+ '+data[i].point
			}
			else if(data[i].point < 0){
				pointText = '- '+ -data[i].point
				styleClass = 'decrease';
			}
			else if(data[i].point === 0){
				pointText = '± '+data[i].point
			}
			result.push({
				date: date.toLocaleDateString(),
				details: data[i].source_text.replace(/\{.*\}/, data[i].source_variable),
				status: curr_status,
				points: pointText,
				styleClass: styleClass,
			})
		}
		return result
	}
	useEffect(() => {
		if(checkinToggle){
			setRewardHistory({next: `${process.env.REACT_APP_BACKEND_URL}/reward`, results: []})
			setRewardHistoryData([])
		}
	}, [checkinToggle])
  useEffect(() => {
    if (rewardHistory && rewardHistory.next) {
      const getNextRewardHistory = async () => {
        try {
          const response = await fetch(rewardHistory.next, {
            method: "GET",
            credentials: "include",
          });
          const data = await response.json();
          setRewardHistory(data);
					const formattedData = formatFetchResult(data.results)
					setRewardHistoryData([...rewardHistoryData, ...formattedData]);
        } catch (error) {
          console.error("Error fetching reward history:", error);
        }
      };
      getNextRewardHistory();
    }
  }, [rewardHistory, rewardHistoryData]);

  return (
    <div ref={targetRef} className={styles["history-container"]}>
      <div className={styles.historyHeader}>
        <h2 className={styles.historyHeaderName}>{t("reward.history")}</h2>
        <div className={styles.pagination}>
          <span>
            {Math.min(currPage * 10 + 1, rewardHistoryData.length)}-
            {Math.min((currPage + 1) * 10, rewardHistoryData.length)} /{" "}
            {rewardHistoryData.length}
          </span>
          <button
            onClick={currPage > 0 ? prevPage : null}
            disabled={currPage === 0}
            className={styles.paginationButton}
          >
            &lt;
          </button>
          <button
            onClick={(currPage + 1) * 10 < rewardHistoryData.length ? nextPage : null}
            disabled={(currPage + 1) * 10 >= rewardHistoryData.length}
            className={styles.paginationButton}
          >
            &gt;
          </button>
        </div>
      </div>
      <table className={`${styles["history-table"]}`}>
				{
				rewardHistoryData.length > 0? 
        <thead className={styles["table-header"]}>
          <tr>
            <th>{t("reward.date")}</th>
            <th>{t("reward.details")}</th>
            <th>{t("reward.status")}</th>
            <th>{t("reward.pointsUpperCase")}</th>
          </tr>
        </thead>
					:
					<p className={styles['no-transations']}> {t("reward.thereAreNoTransactionsYet")} </p>
				}
        <tbody>
          {rewardHistoryData.slice(currPage*10, (currPage+1)*10).map((item, index) => (
            <tr
              key={index}
              className={`${styles.desktop}
								${styles[item.status.toLowerCase()]}
								`}
            >
              <td className={styles["date-col"]}>{item.date}</td>
              <td className={styles["details-col"]}>{item.details}</td>
              <td className={styles["status-col"]}>
                <span
                  className={`${styles.status} ${
                    styles[item.status.toLowerCase()]
                  }`}
                >
                  {t(`reward.${item.status.toLowerCase()}`)}
                </span>
              </td>
              <td
                className={`
								${styles[item.styleClass]}
                ${styles["points-col"]} ${styles.desktop}`}
              >
                {item.points}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {rewardHistoryData.slice(currPage*10, (currPage+1)*10).map((item, index) => (
        <div
          key={`${index}`}
          className={`${styles["mobile-row"]} ${styles.mobile}
						${styles[item.status.toLowerCase()]}
          `}
        >
          <div
            className={`
                ${styles["points-col"]} ${styles[item.styleClass]}
							`}
          >
            <div className={styles["points-badge"]}>{item.points}</div>
          </div>
          <div className={styles["details-col"]}>{item.details}</div>
          <div className={`${styles["date-status-col"]}`}>
            <div>{item.date}</div>
            <div
              className={`${styles.status} ${
                styles[item.status.toLowerCase()]
              }`}
            >
              {t(`reward.${item.status.toLowerCase()}`)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PointsHistory;
