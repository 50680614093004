import axios from "axios";

export const loginGoogleOauth = async (accessToken, invite_id) => {
    
		const param = invite_id? `?invitor=${invite_id}` : ''
    const oauthUrl = `${process.env.REACT_APP_BACKEND_URL}/user-login/google_oauth2/${param}`
    // console.log('Google login path:' + oauthUrl)
    try{
        const response = await axios.post(oauthUrl, {
            accessToken: accessToken, 
        },
					{withCredentials: true},
				);
        const kangacook_user_id = response.data;
        return kangacook_user_id;

    } catch (error) {
        console.error("Error in GoogleOauth: ", error)
        throw error;
    }
  };
