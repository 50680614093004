import React, { useState, useEffect, useRef } from 'react';
import TrashIcon from '../../../components/icons/recipeCreate/trash.svg';
import TrashGrayIcon from '../../../components/icons/recipeCreate/trashGray.svg';
import PlusIcon from '../../../components/icons/recipeCreate/plus.svg';
import UploadIcon from '../../../components/icons/recipeCreate/upload.svg';
import StepIndicator from './StepIndicator';
import ErrorToast from './ErrorToast';
import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from "react-i18next";
import heic2any from 'heic2any';


const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const mapLanguage = (lang) => {
  const langMap = {
    'zh-TW': 'zh_tw',
    'zh-CN': 'zh',
  };
  return langMap[lang] || lang.toLowerCase();
};

const RecipeCreateStepThree = ({
	setIsDirty,
  onPrevious,
  onPublish,
  setFormData,
  formData,
}) => {
  // TODO: Implement language selection
  const [lang, setLang] = useState(mapLanguage(getCookie('lang') || 'en'));
  const [steps, setSteps] = useState(
    formData.instructions?.length
      ? formData.instructions.map((instruction, index) => ({
          description: instruction[`description`] || '',
          image: instruction['image'] || null,
        }))
      : [{ description: '', image: null }]
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const fileInputRefs = useRef([]);
  const instScrollRef = useRef(null)
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  // Sync steps with formData whenever steps change
  useEffect(() => {
    const formattedInstructions = steps.map((step, index) => ({
      step_number: index + 1,
      description: step.description,
      // Keeping the image in formData but will be removed before publishing
      image: step.image ? step.image : null,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      instructions: formattedInstructions,
    }));
  }, [steps, lang, setFormData]);

  // Function to convert image to Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleInputChange = (index, event) => {
    const updatedSteps = [...steps];
    updatedSteps[index].description = event.target.value;
    setSteps(updatedSteps);
  };

  const handleImageChange = async (index, event) => {
    const file = event.target.files[0];
    const updatedSteps = [...steps];

    if (file) {
        const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/heic'];

        if (!validTypes.includes(file.type)) {
            setErrorMessage(t("recipeCreateStepThree.errorMessageFormats"));
            updatedSteps[index].image = null;
        } else if (file.size > 10 * 1024 * 1024) {
          setErrorMessage(`${t("recipeCreateStepThree.errorMessageFileSize")} (${file.size / (1024 * 1024)} MB)`);
            updatedSteps[index].image = null;
        } else {
            try {
                let convertedFile = file;

                // 如果文件是 HEIC 格式，转换为 JPEG 格式
                if (file.type === 'image/heic') {
                    const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                    convertedFile = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
                        type: 'image/jpeg',
                    });
                    console.log("HEIC 转换成功:", convertedFile);
                }

                // 转换为 Base64 格式
                const base64Image = await convertToBase64(convertedFile);
                updatedSteps[index].image = base64Image; 

                setErrorMessage(null); 
            } catch (error) {
                console.error("图片处理失败:", error);
                setErrorMessage(t("recipeCreateStepThree.errorMessageRestrictedFormats"));
                updatedSteps[index].image = null;
            }
        }

        setSteps(updatedSteps); 
    }
};


  const handleDrop = (index, event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleImageChange(index, { target: { files: [file] } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [instAdded, setInstAdded] = useState(-1)
  const handleAddStep = (index) => {
	setSteps(steps.toSpliced(index, 0,{ description: '', image: null }));

    fileInputRefs.current.push(React.createRef()); // Add new ref for the new step
	setInstAdded(index)
  };
  useEffect(() => {
	if (instAdded >= 0){
	  scrollToInst()
	}
  }, [instAdded])

  const scrollToInst = () => {
	if (instScrollRef && instScrollRef.current){
	  window.scrollTo({
		top: instScrollRef.current.offsetTop - 80, 
		behavior: 'smooth'
	  })
	}
  }

  const handleRemoveStep = () => {
    const updatedSteps = steps.filter((_, i) => i !== deleteIndex);
    setSteps(updatedSteps);
    fileInputRefs.current.splice(deleteIndex, 1); // Remove the corresponding ref
	deleteIndex === steps.length - 1 && setInstAdded(steps.length-2)
	setShowModal(false)
  };
  const openModal = (index) => {
	setDeleteIndex(index)
	setShowModal(true)
  }

  const validateSteps = () => {

	// Step one
    if (!formData.title.trim()) {
      console.log(formData)
      setErrorMessage(t("recipeCreateStepThree.errorMessageMissingRecipeName"));
      return false
    } else if (formData.title.length > 200) {
      setErrorMessage(t("recipeCreateStepThree.errorMessageTitleTooLong"));
	    return false
    }

    if (!formData.cover_picture) {
      setErrorMessage(t("recipeCreateStepThree.errorMessageCoverPicture"));
	    return false
    }

    if (!formData.description.trim()) {
      setErrorMessage(t("recipeCreateStepThree.errorMessageRecipeDescription"));
	    return false
    }

    if (
      !formData.cook_time ||
      formData.cook_time <= 0
    ) {
      setErrorMessage(t("recipeCreateStepThree.errorMessageCookingTime"));
	    return false
    }

    if (
      !formData.servings ||
      formData.servings <= 0
    ) {
      setErrorMessage(t("recipeCreateStepThree.errorMessageServings"));
	    return false
    }

	// Step two
	for (let i = 0; i < formData.ingredients.length; i++){
	  let ingredient = formData.ingredients[i];
      if (
        ingredient.name.trim().length === 0 
      ) {
        setErrorMessage(t("recipeCreateStepThree.errorMessageIngredient"));
        return false;
      }
	}

    // Validate cookware
	for (let i = 0; i < formData.cookwares.length; i++){
	  let cookware = formData.cookwares[i];
      if (cookware.name.trim().length === 0 ||
		  !cookware.quantity || 
		  cookware.quantity.toString().trim().length === 0
	  ) {
        // Ensure quantity is string
        setErrorMessage(t("recipeCreateStepThree.errorMessageCookwares"));
        return false;
      }
	}

	// Step three
    for (const step of steps) {
      if (!step.description || !step.description.trim()) {
        setErrorMessage(t("recipeCreateStepThree.errorMessageInstructions"));
        return false;
      }
    }
    setErrorMessage(null);
    return true;
  };

  const handlePublish = () => {
    if (validateSteps()) {
      onPublish();
    }
  };

  const handleDeleteImage = (index) => {
    // 删除当前步骤的图片
    const updatedSteps = [...steps];
    updatedSteps[index].image = null;
    setSteps(updatedSteps);  // 更新步骤数据
  };
  

  return (
    <div className="max-w-[1120px] bg-gray-50 rounded-lg flex flex-col gap-[20px]">
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleRemoveStep}
        message={t("recipeCreateStepThree.deleteInstructionConfirm")}
      />
      {errorMessage && (
        <ErrorToast message={errorMessage} />
      )}
      <h5 className="text-[20px] font-normal leading-[34px] tracking-[0.4px] text-gray-900">
        {t("recipeCreateStepThree.recipeStep")}
      </h5>

      {steps.map((step, index) => (
		<React.Fragment key={index}>
		{
		  index === instAdded && 
		  <div
			className='w-0 h-0'
			ref={instScrollRef}
		  />
		}
        <div
          key={index}
          className="flex justify-between items-start border-gray-300 rounded-t-lg gap-[12px]"
        >
          {/* Step Indicator */}
          <div className="flex flex-col items-center">
            <StepIndicator
              stepNumber={index + 1}
              isActive={!!step.description || !!step.image}
            />
          </div>

          {/* Step Content */}
          <div className="flex flex-col flex-1 gap-[12px]">
            <div className="flex flex-1 justify-between rounded-t-lg">
              <span className="mt-2 text-gray-700">{t("recipeCreateStepThree.step")} {index + 1}</span>
              {/* Add and Remove Buttons */}
              <div className="flex justify-end gap-[12px] ml-4">
								{
									steps.length < 30 &&
									<button
										onClick={() => {setIsDirty(true); handleAddStep(index+1)}}
										className="p-2 text-white rounded-lg"
									>
										<img src={PlusIcon} alt="Add Step" />
									</button>
								}
                <button
                  onClick={() => {setIsDirty(true); openModal(index)}}
                  className="p-2 text-white rounded-lg"
                  disabled={steps.length === 1}
                >
                  <img
                    src={steps.length === 1 ? TrashGrayIcon : TrashIcon}
                    alt="Remove Step"
                  />
                </button>
              </div>
            </div>

            <div className="flex flex-col tablet:flex-row gap-[12px] mt-2">
              {/* Step Description */}
              <div className="flex flex-1 h-[256px]">
                <textarea
                  placeholder={t("recipeCreateStepThree.addStepDescriptions")}
                  value={step.description}
                  onChange={(e) => {setIsDirty(true); handleInputChange(index, e)}}
                  className="w-full h-[256px] p-[16px] font-normal text-[16px] leading-[22px] tracking-[0.28px] resize-none"
                />
              </div>

              {/* Image Upload */}
              <div
          className="flex flex-col tablet:flex-1 items-center justify-center border-2 border-dashed border-gray-300 bg-white rounded-lg p-[16px] gap-[12px] text-center h-[256px]"
          onDrop={(event) => handleDrop(index, event)}
          onDragOver={handleDragOver}
     >
          <input
               type="file"
               className="hidden"
               ref={(el) => (fileInputRefs.current[index] = el)}
               onChange={(e) => {setIsDirty(true); handleImageChange(index, e)}}
          />
          {step.image ? (
               <div className="relative w-full h-full">
                    {/* 显示图片 */}
                    <img
                         src={step.image}
                         alt={`Step ${index + 1}`}
                         className="rounded-lg object-scale-down hover:cursor-pointer object-contain w-full h-full"
                         onClick={() => fileInputRefs.current[index].click()}
                    />
                    {/* 右上角的删除按钮 */}
                    <button
                         className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                         onClick={() => {setIsDirty(true); handleDeleteImage(index)}}  // 点击时删除图片
                    >
                         &times;
                    </button>
               </div>
          ) : (
               <div className="flex justify-center flex-col gap-[12px] items-center">
                    <button
                         onClick={() => {setIsDirty(true); fileInputRefs.current[index].click()}}
                         className="w-fit p-2 rounded-full bg-gray-100 shadow-xs"
                    >
                         <img src={UploadIcon} alt="Upload" />
                    </button>
                    <p className="text-gray-400 font-normal text-[14px] leading-[22px] tracking-[0.28px]">
                      {t("recipeCreateStepThree.uploadYourImage")}
                    </p>
                    <p className="text-gray-400 font-normal text-[14px] leading-[22px] tracking-[0.28px]">
                      {t("recipeCreateStepThree.restrictedFormats")}
                    </p>
                    <button
                         onClick={() => {setIsDirty(true); fileInputRefs.current[index].click()}}
                         className="w-fit px-4 py-2 bg-white border border-gray-300 rounded-full shadow-sm h-[44px] min-w-[80px]"
                    >
                         {t("recipeCreateStepThree.browse")}
                    </button>
               </div>
          )}
     </div>
            </div>
          </div>
        </div>
		</React.Fragment>
      ))}

      {/* Action Buttons */}
      <div className="flex justify-end mt-8 space-x-4">
        <button
          onClick={() => {window.scrollTo({top: 0}); onPrevious()}}
          className="bg-white text-primary-600 px-4 rounded-full border border-primary-300 h-[44px]"
        >
          {t("recipeCreateStepThree.previousStep")}
        </button>
        <button
          onClick={() => {setIsDirty(false); window.scrollTo({top: 0}); handlePublish()}}
          className="bg-primary-500 text-white px-4 rounded-full h-[44px]"
        >
          {t("recipeCreateStepThree.publish")}
        </button>
      </div>
    </div>
  );
};

export default RecipeCreateStepThree;
