import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { confirmSignUp, resendConfirmationCode } from '../hook/awsAuthServices';
import './EmailVerification.css';  // 假设我们创建了一个类似的样式文件
import { useTranslation } from "react-i18next";
import { useLocation} from 'react-router-dom'

export default function EmailVerification({ email, setErrorMessage }) {
	const loc = useLocation();
	const queryParams = new URLSearchParams(loc.search)
	const invite_id = queryParams.get('invite_id')
	const username = queryParams.get('username')

  const navigate = useNavigate();
  const [otpValues, setOtpValues] = useState(new Array(6).fill(''));
  const [timer, setTimer] = useState(120); // 初始倒计时时间
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const inputRefs = useRef([]); // 用于存储输入框的refs
  const { t } = useTranslation();

  useEffect(() => {
    if (isResendDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 1) {
            return prevTimer - 1;
          } else {
            clearInterval(interval);
            setIsResendDisabled(false); // 倒计时结束，启用Resend按钮
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval); // 清除定时器
    }
  }, [isResendDisabled]);

  const handleChange = (value, index) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
    } else if (!value && index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
    }
  };

  const onFinish = async () => {
    const otp = otpValues.join('');  // 将验证码拼接成字符串
    try {
        await confirmSignUp(email, otp);
        navigate('/login');  // 成功后跳转到登录页面
    } catch (error) {
      if (setErrorMessage) {
        setErrorMessage(`Sign up failed: ${error.message}`);
      }
    }
  };

  const onResend = async () => {
    try {
      await resendConfirmationCode(email);
      setTimer(120); // 重置计时器
      setIsResendDisabled(true); // 重新禁用按钮
    } catch (error) {
      console.log(`Resend confirmation code error: ${error}`);
    }
  };

  return (
    <>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item 
          label={t("emailVerification.verificationCode")}
          name="verificationCode" 
          style={{ marginBottom: 0 }}
        >
          <div className="otp-container">
            <div className="otp-input-container">
              {otpValues.map((value, index) => (
                <Input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`otp-input ${value ? 'filled' : ''}`}
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(e.target.value, index)}
                  placeholder="0"
                />
              ))}
            </div>
            <Button
              type="text"
              onClick={onResend}
              className="resend-button"
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend (${timer}s)` : 'Resend'}
            </Button>
          </div>
        </Form.Item>

        <Button type="primary" htmlType="submit" className="custom-submit-button">
          {t("emailVerification.submit")}
        </Button>
      </Form>
    </>
  );
}
