import React, { useState, useEffect, useCallback } from "react";
import "./Item.css"; // Importing the CSS file for styling the component
import defaultImage from "../icons/RecipeCard.jpeg"; // Default image for items without an available image
import trashCan from "../icons/TrashCan.svg"; // Trash can icon for the delete action
import { useTranslation } from "react-i18next"; // i18next hook for handling translations
import axios from "axios"; // Axios library for making HTTP requests

// Main component function
export default function Item({ value, onDelete, selectedStore }) {
  const { t } = useTranslation(); // Hook to get translation function
  const [quantity, setQuantity] = useState(value.quantity); // State for item quantity
  const [displayWarning, setDisplayWarning] = useState(false); // State to display warning if quantity exceeds a threshold
  const [timer, setTimer] = useState(null); // Timer for debouncing quantity update
  const [isConfirming, setIsConfirming] = useState(false); // State for confirming deletion

  // Checks if the product is available in the selected store
  const isProductAvailable = useCallback(() => {
    return (
      value[`${selectedStore}_name`]?.length > 0 &&
      value[`${selectedStore}_price`]?.length > 0 &&
      value[`${selectedStore}_image`]?.length > 0
    );
  }, [value, selectedStore]);

  // Handles deletion of an ingredient
  const handleDeleteIngredient = async () => {
    setIsConfirming(false); // Close confirmation dialog
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/delete-by-ingredient/${value.ingredient_id}/`,
        { withCredentials: true }
      );
      onDelete(value); // Notify parent component about the deletion
    } catch (error) {
      console.error("Error deleting ingredient:", error); // Log error if deletion fails
    }
  };

  // Handles changes to the quantity input field
  const handleQuantityChange = (e) => {
    const newQuantity = Math.max(0, parseInt(e.target.value, 10) || 0); // Ensure the quantity is non-negative

    if (newQuantity === 0) {
      setIsConfirming(true); // Trigger delete confirmation if quantity is zero
      return;
    }

    setQuantity(newQuantity); // Update the local quantity state

    if (timer) clearTimeout(timer); // Clear any existing timer

    // Set a timer to delay the API call for updating the quantity
    setTimer(
      setTimeout(async () => {
        try {
          await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/${value.ingredient_id}/`,
            { quantity: newQuantity },
            { withCredentials: true }
          );
        } catch (error) {
          console.error("Error updating quantity:", error); // Log error if update fails
        }
      }, 1000) // Delay of 1 second
    );
  };

  // Effect to show a warning if quantity exceeds 9
  useEffect(() => {
    setDisplayWarning(quantity > 9);
  }, [quantity]);

  // Effect to update local quantity state when the `value` prop changes
  useEffect(() => {
    setQuantity(value.quantity);
  }, [value]);

  // Renders the delete confirmation dialog
  const renderDeleteConfirmation = () => (
    <div className="cart-item-delete-confirm">
      <div className="cart-item-delete-confirm-card">
        <p className="cart-item-delete-confirm-text">{t("cart.removeIngredient")}</p>
        <div className="cart-item-delete-confirm-buttons">
          <div
            className="secondary-text-wrapper"
            onClick={(e) => {
              setIsConfirming(false); // Cancel deletion
              e.stopPropagation();
            }}
          >
            <p className="secondary-text">{t("cart.cancel")}</p>
          </div>
          <div className="primary-text-wrapper" onClick={handleDeleteIngredient}>
            <p className="primary-text">{t("cart.confirm")}</p>
          </div>
        </div>
      </div>
    </div>
  );

  // Renders product details
  const renderProductDetails = () => (
    <div className="cart-item-text-container">
      <p className="cart-item-original-name">{value.name}</p> {/* Always display the original ingredient name */}
      <p className="cart-item-name">
        {isProductAvailable() ? value[`${selectedStore}_name`] : value.name} {value.serving}
      </p> {/* Display store name if available, otherwise original name */}
      <p className="cart-item-price">
        {
					(
						!value[`${selectedStore}`] && t("cart.loading")
					) || 
					(
						isProductAvailable() && value[`${selectedStore}_price`]
							? `$ ${value[`${selectedStore}_price`]}`
							: t("cart.notAvailable")
					)
				}
      </p> {/* Display price if available, otherwise show 'not available' */}
    </div>
  );

  // Renders the input field for quantity and the delete option
  const renderOptions = () => (
    <div className="cart-item-option-container">
      {isProductAvailable() && (
        <>
          <p className="cart-item-quantity-text">{t("cart.qty")}:</p>
          <input
            type="number"
            step="1"
            pattern="[0-9]*"
            min="0"
            value={quantity}
            onChange={handleQuantityChange}
          />
        </>
      )}
      <span className="cart-item-trash-can">
        <img src={trashCan} alt="trash-can" onClick={() => setIsConfirming(true)} />
      </span> {/* Delete option */}
      {displayWarning && (
        <p className="cart-item-warning-text">{t("cart.warningQtyExceed9")}</p>
      )} {/* Show warning if quantity exceeds threshold */}
    </div>
  );

  // Main component rendering
  return (
    <div
      className={`cart-item-component ${
        isProductAvailable() ? "" : "cart-item-unavailable"
      }`}
    >
      {isConfirming && renderDeleteConfirmation()} {/* Conditionally show delete confirmation */}
      <div className="cart-item-image-container">
				{
					value[`${selectedStore}`] ? 
					<img
						src={isProductAvailable() ? value[`${selectedStore}_image`] : defaultImage}
						alt="product"
					/>
					:
					<div className="cart-loading-spinner"> </div>
				}
      </div>
      <div className="cart-item-details-container">
        {renderProductDetails()} {/* Render product details */}
        {renderOptions()} {/* Render quantity input and delete option */}
      </div>
    </div>
  );
}
