import React from "react";
import Button from "../../../components/Button/Button";
import styles from "./RedeemPopupContent.module.css";
import {useNavigate} from 'react-router-dom'
import { useTranslation } from "react-i18next";

const RedeemPopupContent = ({ currentPoints, togglePopup }) => {
  const { t } = useTranslation();
	const navigate = useNavigate()
	const redeem = () => {
		togglePopup()
		navigate('/rewardshippingcard')
	}
  // currentPoints = 0;
  return (
    <div className={styles.policyBox}>
      {currentPoints < 400 ? (
        <>
          <h2>{t("reward.sorryYouDontHaveEnoughPoints")}</h2>
          <div>
            <p>{t("reward.youNeed")} {400 - currentPoints} {t("reward.morePointsToRedeemTryToEarnMorePoints")}</p>
          </div>
          <div className={styles.buttonBox}>
            <Button
              content={t("reward.gotIt")}
              path="/NA"
              onClick={togglePopup}
              classNames={"btn primary default"}
            />
          </div>
        </>
      ) : (
        <>
          <h2>{t("reward.areYouSureYouWantToRedeemPoints")}</h2>
          <div>
            <p>{t("reward.youllInputTheShippingInformationFromTheNextPage")}</p>
          </div>
          <div className={styles.buttonBox}>
            <Button
              content={t("reward.cancel")}
              path="/NA"
              onClick={togglePopup}
              classNames={"btn secondary default"}
            />
            <Button
              content={t("reward.redeem")}
              path="/NA"
              onClick={redeem}
              classNames={"btn primary default"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RedeemPopupContent;
