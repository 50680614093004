import React from 'react'
import './DishList.css'
import Dish from './Dish'
import '../../components/components.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function DishList({dishes, removeDish}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	// <p className='cart-dish-list-text-more'>
	return (
		<div className='cart-dish-list'>
			<div className='cart-dish-list-header'>
				<p className='cart-dish-list-title'>
					{t("cart.yourDishes")}
				</p>
				<div
					className='tertiary-text-container'
					onClick={()=>navigate('/')}
				>
					<p className='prime-tertiary-text'>
						{t("cart.exploreMoreRecipes")}
					</p>
				</div>
			</div>
			<div className='cart-dish-list-component'>
				{
					dishes && dishes.length > 0?
					dishes.map((value, index) => {
						return(
							<Dish
								removeDish={removeDish}
								description={value.description}
								recipe_id={value.recipe_id}
								title={value.title}
								cover_picture={value.cover_picture}
								key={index} />
						)
					})
					:
					<p className='cart-dish-list-empty-text'>
						{t("cart.nothingToShow")}
					</p>
				}
			</div>
		</div>
	)
}

