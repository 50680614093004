import React, { useState } from "react";
import styles from "./RedeemGuideCard.module.css";
import chopsticksImage from "./chopsticks.png";
import GiftIcon from "../../../components/icons/gift.png";
import RedeemPopupContent from "./RedeemPopupContent";
import Popup from "../../../components/Popup/Popup";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../../UserManagement/hook/Authenticated";
import { useNavigate } from 'react-router-dom';

const RedeemGuideCard = ({ currentPoints }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup((prevPopup) => !prevPopup);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("reward.howToRedeemPoints")}</h2>
      <div className={styles.card}>
        <img
          src={chopsticksImage}
          alt="Kangacook Original Chopsticks"
          className={styles.image}
        />
        <div className={styles.cardContent}>
          <h3 className={styles.productName}>{t("reward.kangacookOriginalChopsticks")}</h3>
          <div className={styles.pointsContainer}>
            <span className={styles.points}>400 {t("reward.points")}</span>
            <button
              className={styles.redeemButton}
              onClick={() => {
                if (!isAuthenticated()) {
                  navigate('/login');
                  return;
                }
                setShowPopup(true);
              }}
            >
              <img src={GiftIcon} alt="gift-icon" className={styles.giftIcon} />
            </button>
          </div>
        </div>
      </div>

      {showPopup && (
        <Popup>
          <RedeemPopupContent currentPoints={currentPoints} togglePopup={togglePopup} />
        </Popup>
      )}
    </div>
  );
};

export default RedeemGuideCard;
