import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet'
import { Row, Col, ColorPicker } from 'antd';
// import KangaSignup from '../components/KangaSignup';
import './SignupPage.css';
// import GoogleOauth from '../../components/AlternativeLogin/GoogleOauth';
import GoogleOauth_Signup from '../../components/AlternativeLogin/GoogleOauth-Signup';
import { useTranslation } from "react-i18next";
import { useLocation} from 'react-router-dom'

export default function SignupPage() {
	const loc = useLocation();
	const queryParams = new URLSearchParams(loc.search)
	const invite_id = queryParams.get('invitor')
	const [showPopup, setShowPopup] = useState(false)
	const [showError, setShowError] = useState(false)
	const [invitorUsername, setInvitorUsername] = useState("Kangacook user")

  const { t } = useTranslation();

	useEffect(() => {
		const getInvitorUsername = async() => {
			try{
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-identity/${invite_id}/ref-username`,{
					method: 'GET',
				});
				const data = await response.json();
				setInvitorUsername(data.username)
			}
			catch(error){
				console.error(error)
				setShowPopup(false)
				setShowError(true)
			}
		}
		if(invite_id){
			setShowPopup(true)
			getInvitorUsername()
		}
	}, [invite_id])
  return(
    <div className="signup-container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Signup"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/signup/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Signup</title>
      <link rel="canonical" href={`/signup`} />
	  </Helmet>
		{showError && (
				<div className='popup-overlay'>
						<div className='popup-content' onClick={(e) => e.stopPropagation()}>
								<h2>ERROR: Invalid Invite Link</h2>
								<p> You can continue to signup without an invite link</p>
								<div className='popup-buttons' onClick={() => setShowPopup(false)}>
										<button className='view-cart-button'>
											Continue without invite link
										</button>
								</div>
						</div>
				</div>
		)}
		{showPopup && (
				<div className='popup-overlay'>
						<div className='popup-content' onClick={(e) => e.stopPropagation()}>
								<h2>Invitation from <b>{invitorUsername}</b></h2>
								<p> <b>{invitorUsername}</b> invited you to join Kangacook</p>
								<div className='popup-buttons' onClick={() => setShowPopup(false)}>
										<button className='view-cart-button'>
											Continue
										</button>
								</div>
						</div>
				</div>
		)}
      <div className='signup-image-section'></div>
      <div className='signup-form-section'>
        <div className='signup-form-container'>
	  	<a href='/'>
          <img src="/logo.png" alt="Logo" className="signup-logo" /> 
	  </a>
          <div className="signup-form-header">
            <h1 className="signup-page-title">{t("signUpPage.signUpTitle")}</h1>
            <p className="signup-page-subtitle">{t("signUpPage.signUpSubtitle")}</p>
          </div>
          <div className="signup-alterAccount">
            {/* Not for MVP */}
            {/*<button className="signup-page-button">
              <img src="/facebook.png" alt="Facebook Icon" className="facebook-icon" /> 
              Sign up with Facebook
            </button>  */}
              <GoogleOauth_Signup /> {/* google组件 */}

            {/* Not for MVP */}
            {/*<button className="signup-page-button">
              <img src="/line.png" alt="Line Icon" className="line-icon" /> 
              Sign up with Line
            </button> */}     
          </div>
          <div className="signup-dividerheader">
            <div className="signup-divider">
              <div className="signup-divider-line"></div>
                <span>{t("signUpPage.or")}</span>
              <div className="signup-divider-line"></div>
            </div>
          </div>
          <div className='email-signup'>
            <a href={invite_id? `/EmailSignup/?invitor=${invite_id}` : '/EmailSignup'}>
              <button className="signup-page-button">
                <img src="/mail.png" alt="Mail Icon" className="mail-icon" /> 
                {t("signUpPage.signUpButton")}
              </button>
            </a>
          </div>
          <div className='footer-container'>
            <div className="has-account-container">
              <span className="has-account-text">{t("signUpPage.hasAccountText")}</span>
              <a href="/login" className="login-link">{t("signUpPage.loginButton")}</a>
            </div>
            <div className="signup-agreement-text">
              {t("signUpPage.agreementText")}
              <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("signUpPage.terms")} </a>
              <br/>
              {t("signUpPage.and")} 
              <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("signUpPage.privacy")}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
