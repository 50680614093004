import React from "react";
import "./ItemList.css";
import Item from "./Item";
import {useState} from "react";
import { useTranslation } from "react-i18next";

export default function ItemList({
	recipes,
	listRecipes, setListRecipes, 
	selectedRecipe, setSelectedRecipe, 
	currCartItems,
	handleDelete,
	selectedStore,
}) {
	const { t } = useTranslation();
  return (
    <div className="cart-item-list">
			<p className='cart-item-list-title'>
				{t("cart.items")}
			</p>
      {recipes && recipes.length > 0 ? (
        <>
          <div className="cart-item-list-type">
            {listRecipes ? (
              <>
                <div
                  className="tab-button-secondary-container"
                  onClick={() => setListRecipes(false)}
                >
									<p className='tab-button-text'>
										{t("cart.byIngredients")}
									</p>
                </div>
                <div
                  className="tab-button-secondary-active-container"
                  onClick={() => setListRecipes(true)}
                >
									<p className='tab-button-active-text'>
										{t("cart.byRecipe")}
									</p>
                </div>
              </>
            ) : (
              <>
                <div
                  className="tab-button-secondary-active-container"
                  onClick={() => setListRecipes(false)}
                >
									<p className='tab-button-active-text'>
										{t("cart.byIngredients")}
									</p>
                </div>
                <div
                  className="tab-button-secondary-container"
                  onClick={() => setListRecipes(true)}
                >
									<p className='tab-button-text'>
										{t("cart.byRecipe")}
									</p>
                </div>
              </>
            )}
          </div>
          {listRecipes && (
            <div className="cart-item-list-recipe">
              {recipes.map((recipe, index) => {
                return (
                  <div className="cart-item-recipe" key={index}>
                    {recipe.recipe_id === selectedRecipe ? (
                      <div
                        className="tab-button-active-container"
                        onClick={() => {setSelectedRecipe(recipe.recipe_id);}}
                      >
                        <p className="tab-button-active-text"> {recipe.title} </p>
                      </div>
                    ) : (
                      <div
                        className="tab-button-container"
                        onClick={() => {setSelectedRecipe(recipe.recipe_id);}}
                      >
                        <p className="tab-button-text"> {recipe.title} </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
		  
          <div className="cart-item-list-component">
            {currCartItems.map((value, index) => {
              return <Item value={value} key={index} onDelete={handleDelete} selectedStore={selectedStore}/>;
            })}
          </div>
					{/* <div className="cart-item-list-placeholder">
						<p> Hey! We are cooking it up... </p>
					</div> */}
          <div className="cart-item-list-total">
            <p className="cart-item-list-total-text">{t('cart.total')}</p>
            <p className="cart-item-list-total-price">
              {currCartItems.reduce((total, value) => {
                const price = Array.isArray(value[`${selectedStore}_price`]) 
                  ? value[`${selectedStore}_price`].reduce((sum, price) => sum + price, 0) 
                  : value[`${selectedStore}_price`] || 0;
                return total + price;
              }, 0).toFixed(2)}
            </p>
          </div>
        </>
      ) : (
        <p className="cart-item-list-empty-text">
						{t("cart.nothingToShow")}
			 	</p>
      )}
    </div>
  );
}
