import React, { useRef, useState, useEffect } from 'react';
import TrashIcon from '../../../components/icons/recipeCreate/trash.svg';
import TrashGrayIcon from '../../../components/icons/recipeCreate/trashGray.svg';
import PlusIcon from '../../../components/icons/recipeCreate/plus.svg';
import AlertIcon from '../../../components/icons/recipeCreate/alert.svg';
import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from "react-i18next";

// Function to get a cookie value by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Map language codes to corresponding values
const mapLanguage = (lang) => {
  const langMap = {
    'zh-TW': 'zh_tw',
    'zh-CN': 'zh', // Adjust mapping if needed
  };
  return langMap[lang] || lang.toLowerCase(); // Fallback to lowercase if not mapped
};

const RecipeCreateStepTwo = ({ setIsDirty, onNext, onPrevious, setFormData, formData }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(mapLanguage(getCookie('lang') || 'en'));
  const ingScrollRef = useRef(null)
  const cwScrollRef = useRef(null)

  // Load ingredients and cookware from formData if available, otherwise default to empty array
  const [ingredients, setIngredients] = useState(
    formData.ingredients?.length
      ? formData.ingredients.map((ingredient) => ({
          name: ingredient[`name`] || '',
          quantity: ingredient[`serving`].split(' ')[0] || '', // Assuming the format is like '1 cup'
          unit: ingredient[`serving`].split(' ')[1] || '',
        }))
      : [{ name: '', quantity: '', unit: '' }]
  );

  const [cookware, setCookware] = useState(
    formData.cookwares?.length
      ? formData.cookwares.map((item) => ({
          name: item[`name`] || '',
          quantity: item.quantity || '',
        }))
      : [{ name: '', quantity: '' }]
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [deleteType, setDeleteType] = useState('');
  const [errors, setErrors] = useState({});

  // Reflect changes in ingredients and cookware to formData immediately
  useEffect(() => {
    const formattedIngredients = ingredients.map((ingredient, index) => ({
      step_number: index + 1,
      name: ingredient.name,
      serving: `${ingredient.quantity} ${ingredient.unit}`,
    }));

    const formattedCookware = cookware.map((item, index) => ({
      step_number: index + 1,
      name: item.name,
      quantity: parseInt(item.quantity, 10),
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      ingredients: formattedIngredients,
      cookwares: formattedCookware,
    }));
  }, [ingredients, cookware, lang, setFormData]);

  const handleAddIngredient = (index) => {
    const newIngredient = { name: '', quantity: '', unit: '' };
    const updatedIngredients = [
      ...ingredients.slice(0, index + 1),
      newIngredient,
      ...ingredients.slice(index + 1),
    ];
    setIngredients(updatedIngredients);
	setIngAdded(i => (i+1)%2)
  };
  const handleAddCookware = (index) => {
    const newCookware = { name: '', quantity: '' };
    const updatedCookware = [
      ...cookware.slice(0, index + 1),
      newCookware,
      ...cookware.slice(index + 1),
    ];
    setCookware(updatedCookware);
	setCwAdded(i => (i+1)%2)
  };

  const [ingAdded, setIngAdded] = useState(-1)
  const [cwAdded, setCwAdded] = useState(-1)

  useEffect(() => {
	if (ingAdded >= 0){
	  scrollToIngredient()
	}
  }, [ingAdded])
  useEffect(() => {
	if (cwAdded >= 0){
	  scrollToCookware()
	}
  }, [cwAdded])

  const scrollToIngredient = () => {
	if (ingScrollRef && ingScrollRef.current){
	  window.scrollTo({
		top: ingScrollRef.current.offsetTop - 80, 
		behavior: 'smooth'
	  })
	}
  }
  const scrollToCookware = () => {
	if (cwScrollRef && cwScrollRef.current){
	  window.scrollTo({
		top: cwScrollRef.current.offsetTop - 80, 
		behavior: 'smooth'
	  })
	}
  }

  const handleIngredientQuantityChange = (index, event) => {
    const value = event.target.value;
    if (value === '' || /^[0-9]+[./]?[0-9]*$/.test(value)) {
      const updatedIngredients = [...ingredients];
      updatedIngredients[index].quantity = value; // Allow empty string or valid number
      setIngredients(updatedIngredients);
    }
  };

  const handleCookwareQuantityChange = (index, event) => {
    const value = event.target.value;
    if (value === '' || /^[1-9]\d*$/.test(value)) {
      const updatedCookware = [...cookware];
      updatedCookware[index].quantity = value; // Allow empty string or valid number
      setCookware(updatedCookware);
    }
  };

  const handleInputChange = (index, event, type) => {
    const { name, value } = event.target;
    if (type === 'ingredient') {
      const updatedIngredients = [...ingredients];
      updatedIngredients[index][name] = value;
      setIngredients(updatedIngredients);
    } else if (type === 'cookware') {
      const updatedCookware = [...cookware];
      updatedCookware[index][name] = value;
      setCookware(updatedCookware);
    }
  };

  const handleRemove = () => {
    if (deleteType === 'ingredient') {
      const updatedIngredients = ingredients.filter(
        (_, i) => i !== selectedIndex
      );
      setIngredients(updatedIngredients);
	  selectedIndex === ingredients.length - 1 && setIngAdded(i => (i+1)%2)
    } else if (deleteType === 'cookware') {
      const updatedCookware = cookware.filter((_, i) => i !== selectedIndex);
      setCookware(updatedCookware);
	  selectedIndex === cookware.length - 1 && setCwAdded(i => (i+1)%2)
    }
    setShowModal(false); // Close modal after deletion
  };

  const openModal = (index, type) => {
    setSelectedIndex(index);
    setDeleteType(type);
    setShowModal(true);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate ingredients
    ingredients.forEach((ingredient) => {
      if (
        !ingredient.name.trim() 
      ) {
        newErrors.ingredients = t("recipeCreateStepTwo.errorMessageAddIngredient");
      }
    });

    // Validate cookware
    cookware.forEach((item) => {
      if (!item.name.trim() || !item.quantity.toString().trim()) {
        // Ensure quantity is string
        newErrors.cookware = t("recipeCreateStepTwo.errorMessageAddCookware");
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      onNext();
    }
  };

  return (
    <div>
      {/* Deletion ConfirmationModal */}
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleRemove}
        message={`${t("recipeCreateStepTwo.deleteConfirm")}${deleteType === 'ingredient' ? t("recipeCreateStepTwo.ingredient") : t("recipeCreateStepTwo.cookware")}?`}
      />

      {/* Ingredients Section */}
      <div className="mb-[48px]">
        <h4 className="text-lg">{t("recipeCreateStepTwo.ingredients")}</h4>
        {ingredients.map((ingredient, index) => (
		  <React.Fragment
            key={index}
		  >
		  {
			index === ingredients.length - 1 && 
			<div
			  className='w-0 h-1'
			  ref={ingScrollRef}
			> </div>
		  }
          <div
            className="flex flex-col tablet:flex-row items-end gap-[12px] w-full pt-[20px]"
          >
            {/* Ingredient Name Input */}
            <div className="w-full flex flex-col items-start gap-[6px] flex-1">
              <label className="text-gray-700">{t("recipeCreateStepTwo.ingredient")} {index + 1}</label>
              <input
                type="text"
                placeholder={t("recipeCreateStepTwo.addIngredient")}
                name="name"
                value={ingredient.name}
                onChange={(e) => {setIsDirty(true); handleInputChange(index, e, 'ingredient')}}
                className="w-full h-[44px] px-[16px] pr-[8px] py-[9px] border border-gray-300 rounded-lg bg-white shadow-xs"
              />
            </div>

            {/* Ingredient Quantity Input */}
            <div className="flex w-full flex-col items-start gap-[6px] flex-1">
			  <div className="w-full flex">
				<label className="text-gray-700 w-[50%]">
					{t("recipeCreateStepTwo.quantity")}
					<label className="ml-1 text-gray-500">({t("recipeCreateStepTwo.optional")})</label>
				</label>
				<label className="text-gray-700">{t("recipeCreateStepTwo.unit")}</label>
				<label className="ml-1 text-gray-500">({t("recipeCreateStepTwo.optional")})</label>
			  </div>
              <div className="flex items-center w-full">
                <input
                  placeholder={t("recipeCreateStepTwo.addQuantity")}
                  name="quantity"
                  value={ingredient.quantity}
                  onChange={(e) => {setIsDirty(true); handleIngredientQuantityChange(index, e)}}
                  className="p-2 border-t border-l border-b rounded-l-lg w-1/2 h-10 px-[16px] pr-[8px] py-[9px] shadow-xs"
                />
                <div className="h-10 w-[1px] bg-gray-300"></div>
                <input
                  name="unit"
                  placeholder={t("recipeCreateStepTwo.unit")}
                  value={ingredient.unit}
                  onChange={(e) => {setIsDirty(true); handleInputChange(index, e, 'ingredient')}}
                  className={`p-2 border-t border-r border-b rounded-r-lg w-1/2 h-10 px-[16px] pr-[8px] py-[9px] shadow-xs ${
                    !ingredient.unit ? 'text-gray-400' : 'text-gray-700'
                  }`}
                />
              </div>
            </div>

            {/* Add and Remove Buttons */}
            <div className="flex items-end gap-[12px]">
			  {
			  ingredients && ingredients.length < 50?
              <button
                onClick={() => {setIsDirty(true); handleAddIngredient(index)}}
                className="p-2 text-white rounded-lg"
              >
                <img src={PlusIcon} alt="Plus Icon" />
              </button>
				:
              <button
                className="p-2 text-white rounded-lg opacity-50 cursor-default"
              >
                <img src={PlusIcon} alt="Plus Icon" />
              </button>
			  }
              <button
                onClick={() => {setIsDirty(true); openModal(index, 'ingredient')}}
                className="p-2 text-white rounded-lg"
                disabled={ingredients.length === 1}
              >
                <img
                  src={ingredients.length === 1 ? TrashGrayIcon : TrashIcon}
                  alt="Trash Icon"
                />
              </button>
            </div>
          </div>
        </React.Fragment>))}
        {errors.ingredients && (
          <div className="flex mt-[6px]">
            <img src={AlertIcon} alt="Alert" className="mr-2" />
            <p className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]">
              {errors.ingredients}
            </p>
          </div>
        )}
      </div>

      {/* Cookware Section */}
      <div className="mb-[48px]">
        <h4 className="text-lg">{t("recipeCreateStepTwo.cookware")}</h4>
        {cookware.map((item, index) => (
		  <React.Fragment
			key={index} 
		  >
		  {
			index === cookware.length - 1 && 
			<div
			  className='w-0 h-0'
			  ref={cwScrollRef}
			> </div>
		  }
          <div className="flex flex-col tablet:flex-row items-end gap-4 pt-[20px]">
            <div className="flex flex-col items-start gap-[6px] flex-1 w-full">
			  <label className="text-gray-700">{t("recipeCreateStepTwo.cookware")} {index + 1}</label>
              <input
                type="text"
                placeholder={`${t("recipeCreateStepTwo.cookware")} ${index + 1}`}
                name="name"
                value={item.name}
                onChange={(e) => {setIsDirty(true); handleInputChange(index, e, 'cookware')}}
                className="w-full h-[44px] px-[16px] pr-[8px] py-[9px] border border-gray-300 rounded-lg bg-white shadow-xs"
              />
            </div>

			<div className="flex-1 flex flex-col w-full gap-[6px]">
			  <label className="text-gray-700">{t("recipeCreateStepTwo.quantity")}</label>

			  <input
				type="number"
				placeholder={t("recipeCreateStepTwo.addQuantity")}
				name="quantity"
				value={item.quantity}
				onChange={(e) => {setIsDirty(true); handleCookwareQuantityChange(index, e)}}
				className="flex-1 p-2 border rounded-lg w-full"
			  />
			</div>

            {/* Add and Remove Buttons */}
            <div className="flex items-end gap-[12px]">
			  {
			  cookware && cookware.length < 15?
              <button
                onClick={() => {setIsDirty(true); handleAddCookware(index)}}
                className="p-2 text-white rounded-lg"
              >
                <img src={PlusIcon} alt="Plus Icon" />
              </button>
				:
              <button
                className="p-2 text-white rounded-lg opacity-50 cursor-default"
              >
                <img src={PlusIcon} alt="Plus Icon" />
              </button>
			  }
              <button
                onClick={() => {setIsDirty(true); openModal(index, 'cookware')}}
                className="p-2 text-white rounded-lg"
                disabled={cookware.length === 1}
              >
                <img
                  src={cookware.length === 1 ? TrashGrayIcon : TrashIcon}
                  alt="Trash Icon"
                />
              </button>
            </div>
          </div>
        </React.Fragment>))}
        {errors.cookware && (
          <div className="flex mt-[6px]">
            <img src={AlertIcon} alt="Alert" className="mr-2" />
            <p className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]">
              {errors.cookware}
            </p>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}

      <div className="flex justify-end mt-8 w-full">
        <div className="flex justify-end space-x-4 w-full">
          <button
            onClick={() => {setCwAdded(-1); setIngAdded(-1); window.scrollTo({top: 0}); onPrevious()}}
            className="tablet:w-fit bg-white text-primary-600 px-4 rounded-full border border-primary-300 h-[44px]"
          >
            {t("recipeCreateStepTwo.previousStep")}
          </button>
          <button
            onClick={() => {setCwAdded(-1); setIngAdded(-1); window.scrollTo({top: 0}); handleNextStep()}}
            className="tablet:w-fit bg-white text-primary-600 px-4 rounded-full border border-primary-300 h-[44px]"
          >
            {t("recipeCreateStepTwo.nextStep")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecipeCreateStepTwo;
