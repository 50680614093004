import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import "./ConfirmRewardShippingCard.css";
import RecipeCardImage from "./Recipe-Card.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function ConfirmRewardShippingCard() {
  const { t } = useTranslation();
  function ConfirmationModal({ onClose }) {
    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <h2>{t("confirmRewardShippingCard.areYouSureYouWantToRedeemPoints")}</h2>
          <div className="modal-buttons">
            <button className="modal-cancel-button" onClick={onClose}>
              {t("confirmRewardShippingCard.cancel")}
            </button>
            <div onClick={redeemReward} className="modal-confirm-button">
              {t("confirmRewardShippingCard.redeem")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const navigate = useNavigate()
  const redeemReward = async () => {
    const data = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      phone: userInfo.phone,
      address: userInfo.address,
      city: userInfo.city,
      state: userInfo.state,
      zipcode: userInfo.zip,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reward/redeem-chopsticks/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          credentials: "include",
        },
      );
			console.log(response)
      if (response.ok) {
        const data = await response.json();
        setCurrPoints(data.point__sum);
				navigate('/reward-redeemed')
      }
			else if(response.status === 403){
        toast.error(t("confirmRewardShippingCard.notEnoughPointsToRedeemChopsticks"));
			}
			else {
        toast.error(t("reward.failedToRedeemChopsticks", { status: response.statusText }));
      }
    } catch (error) {
      toast.error(t("reward.errorRedeemingChopsticks", { status: error.message }));
    }
    console.log(data);
  };

  const { state } = useLocation();
  useEffect(() => {
    if (!state) {
      navigate("/reward");
    }
  }, [state, navigate]);
  
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    address: "",
    specificAddress: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    if (state) {
      const {
        firstName = "",
        lastName = "",
        address = "",
        specificAddress = "",
        city = "",
        state: stateValue = "",
        zip = "",
        email = "",
        phone = "",
      } = state;

      setUserInfo({
        firstName,
        lastName,
        address,
        specificAddress,
        city,
        state: stateValue,
        zip,
        email,
        phone,
      });
    }
  }, [state]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [currPoints, setCurrPoints] = useState(0);

  useEffect(() => {
    const getPoints = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/reward/points`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        if (response.ok) {
          const data = await response.json();
          setCurrPoints(data.point__sum);
        } else {
          console.error("Failed to fetch draft recipe:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching draft recipe:", error);
      }
    };
    getPoints();
  }, []);
  return (
    <>
      <Header />
      <NavigationBar />
      <div className="confirm-rewardshippingcard-page">
        <div className="confirm-rewardshippingcard-container">
          <h4 className="confirm-shipping-info-title">{t("confirmRewardShippingCard.summary")}</h4>
          <div className="reward-summary">
            <div className="reward-title-container">
              <span className="reward-title">{t("confirmRewardShippingCard.reward")}</span>
            </div>
            <div className="reward-content">
              <span className="reward-description">
                {t("confirmRewardShippingCard.kangacookOriginalChopsticks")}
              </span>
              <img
                src={RecipeCardImage}
                alt="Kangacook Original Chopsticks"
                className="reward-image"
              />
            </div>
          </div>

          <hr className="divider-line" />

          <div className="balance-section">
            <div className="balance-row">
              <div className="balance-title-container">
                <span className="balance-title">{t("confirmRewardShippingCard.balance")}</span>
              </div>
              <div className="points-column">
                <span className="balance-value">{t("confirmRewardShippingCard.availablePoints")}</span>
                <span className="balance-value-points">
                  {currPoints} {t("confirmRewardShippingCard.points")}
                </span>
              </div>
            </div>
            <div className="balance-row">
              <div className="balance-title-container">
                <span className="balance-title"></span>
              </div>
              <div className="points-column">
                <span className="balance-value">{t("confirmRewardShippingCard.pointsToBeRedeemed")}</span>
                <span className="balance-value-points">400 {t("confirmRewardShippingCard.points")}</span>
              </div>
            </div>

            <div className="balance-row">
              <div className="balance-title-container">
                <span className="balance-title"></span>
              </div>
              <div className="dotted-line-container">
                <div className="dotted-line"></div>
              </div>
            </div>
            <div className="balance-row">
              <div className="balance-title-container">
                <span className="balance-title"></span>
              </div>
              <div className="points-column">
                <span className="balance-value">{t("confirmRewardShippingCard.remainingPoints")}</span>
                <span className="balance-value-points">
                  {currPoints - 400} {t("confirmRewardShippingCard.points")}
                </span>
              </div>
            </div>
          </div>

          <hr className="divider-line-bottom" />

          <div className="information-section">
            <div className="balance-row">
              <div className="information-title-container">
                <span className="information-title">{t("confirmRewardShippingCard.shippingInformation")}</span>
              </div>
              <div className="points-column">
                <span className="information-value">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
              </div>
            </div>
            <div className="balance-row">
              <div className="information-title-container">
                <span className="information-title"></span>
              </div>
              <div className="points-column">
                <span className="information-value">
                  {`${userInfo.address}${userInfo.specificAddress ? `, ${userInfo.specificAddress}` : ""}, ${userInfo.city}, ${userInfo.state} ${userInfo.zip}, United States`}
                </span>
              </div>
            </div>
            <div className="balance-row">
              <div className="information-title-container">
                <span className="information-title"></span>
              </div>
              <div className="points-column">
                <span className="information-value">{userInfo.email}</span>
              </div>
            </div>
            <div className="balance-row">
              <div className="information-title-container">
                <span className="information-title"></span>
              </div>
              <div className="points-column">
                <span className="information-value">{userInfo.phone}</span>
              </div>
            </div>
          </div>

          <div className="confirmpage-button-group">
            <div className="left-buttons">
              <button
                className="confirmpage-back-button"
                onClick={() => navigate("/rewardshippingcard")}
              >
                {t("confirmRewardShippingCard.back")}
              </button>
            </div>
            <div className="right-buttons">
              <button
                className="confirmpage-cancel-button"
                onClick={() => navigate("/reward")}
              >
                {t("confirmRewardShippingCard.cancel")}
              </button>
              <button
                className="confirmpage-next-button"
                onClick={handleConfirmClick}
              >
                {t("confirmRewardShippingCard.confirm")}
              </button>
            </div>
          </div>
          {isModalOpen && <ConfirmationModal onClose={closeModal} />}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
