import React from 'react'
import './Store.css'
import walmartIcon from '../icons/WalmartIcon.png'
import FudiAsianMartIcon from '../icons/FudiAsianMart-modified.png'
import { useTranslation } from "react-i18next";

export default function Store({selectedStore, setSelectedStore}) {
	const { t } = useTranslation();
	return (
		<div className='cart-store-component-container'>
      <div
				className={`cart-store-fudi-asian-mart-logo ${selectedStore === "fudiAsianMart" && "cart-store-active"}`}
				onClick={() => setSelectedStore("fudiAsianMart")}
      >
				<img src={FudiAsianMartIcon} alt='FudiAsianMartIcon' />
			</div>
			<div
				className={`cart-store-component ${selectedStore === "walmart" && "cart-store-active"}`}
				onClick={() => setSelectedStore("walmart")}
			>
				<div className='cart-store-image-container'>
					<img src={walmartIcon} alt='WalmartIcon'/>
				</div>
				<div
					className={`cart-store-text-container`}
				>
					<p className='cart-store-title'>
						{t("cart.walmart")}
					</p>
				</div>
			</div>
		</div>
	)
}
