import React from "react";
import StarIcon from "../../../components/icons/circle-star.png";
import styles from "./PointsProgressBar.module.css";
import { ScrollContext } from "../../ScrollContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const PointsProgressBar = ({ currentPoints }) => {
  const scrollCtx = useContext(ScrollContext);
  const { scrollToTarget } = scrollCtx;
  const { t } = useTranslation();

  var maxPoints = (~~(currentPoints / 100) + 1) * 100;
	maxPoints = 500;

  const labels = Array.from(
    { length: 6 },
    (_, i) => maxPoints - i * 100
  ).reverse();

  function findLowerBoundIndex(arr, input) {
    let left = 0;
    let right = arr.length;

    while (left < right) {
      let mid = Math.floor((left + right) / 2);
      if (arr[mid] <= input) {
        left = mid + 1;
      } else {
        right = mid;
      }
    }

    return left - 1;
  }

  const lowerBound = findLowerBoundIndex(labels, currentPoints);

  const calculateFillPercentage = () => {
		if(currentPoints >= 600){
			return 100;
		}
    const totalSegments = labels.length;
    const segmentSize = 100 / totalSegments;
    const completedSegments = lowerBound;
    const partialSegment = (currentPoints % 100) / 100;

    return completedSegments * segmentSize + partialSegment * segmentSize;
  };

  return (
    <div className={styles["points-container"]}>
      <div className={styles["title-box"]}>
        <div className={styles.title}>{t("reward.reward")}</div>
      </div>
      <div className={styles["points-progress-container"]}>
        <div className={styles["points-display"]}>
          <span className={styles["points-value"]}>
            <span className={styles["points-icon"]}>
              <img
                src={StarIcon}
                alt="circle-star"
              />
            </span>
            {currentPoints}
          </span>
          <span className={styles["points-label"]}>{t("reward.availablePoints")}</span>
        </div>

        <div className={styles["progress-bar-section"]}>
          <div className={styles["progress-bar-container"]}>
            <div className={styles["progress-header"]}>
              <p className={styles["progress-message"]}>{t("reward.getMorePoints")}</p>
              <button
                onClick={scrollToTarget}
                className={`${styles["view-history"]} ${styles["desktop"]}`}
              >
                {t("reward.viewHistory")}
              </button>
            </div>

            <div className={styles["progress-container"]}>
              <div className={styles["progress-bar"]}>
                <div
                  className={styles["progress-fill"]}
                  style={{ width: `${calculateFillPercentage()}%` }}
                ></div>
              </div>
              <div className={styles["marker-row"]}>
                {[...Array(5 + 1)].map((_, index) => (
                  <div
                    key={index}
                    className={`${styles["mark-labels"]} ${
                      index === 0 ? styles["small-bar"] : ""
                    }`}
                  >
                    <div
                      className={`${styles.marker} ${
                        index > lowerBound
                          ? styles.highlightMarker
                          : styles["white-marker"]
                      }`}
                    />
                    <div className={styles["progress-label"]}>
                      {labels[index]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            onClick={scrollToTarget}
            className={`${styles["view-history"]} ${styles["mobile"]}`}
          >
            {t("reward.viewHistory")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointsProgressBar;
