import "./IngredientCheckbox.css";
import check_img from "../../icons/check.svg";
import dot_img from "../../icons/Ellipse 80.svg";

export default function IngredientCheckbox({ name, index, states, setStates }) {
  const toggleStates = () => {
    if (states[index] === 0) {
      states[index] = 1;
    } else if (states[index] === 1) {
      states[index] = 0;
    }
    setStates([...states]);
  };
  return (
    <div className="recipe-page-ingredient-checkbox-component">
      <div
        onClick={toggleStates}
        className={
					(states.length < index + 1 && "recipe-page-ingredient-checkbox") ||
          (states[index] === 0 && "recipe-page-ingredient-checkbox") ||
          (states[index] === 1 && "recipe-page-ingredient-checkbox-active") ||
          (states[index] === 2 && "recipe-page-ingredient-checkbox-disabled")
        }
      >
        {states.length > index && states[index] !== 0 && <img src={check_img} alt="checked" />}
        {/* <img src={dot_img} alt='bullet-point'/> */}
      </div>
      <p> {name} </p>
    </div>
  );
}
