// import { Divider } from "antd";
import NavigationItem from "./NavigationItem";
import FooterLinks from "./FooterLinks";
import SocialLinks from "./SocialLinks";
import "./NavigationBar.css";
import Divider from "../Divider";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

import {isAuthenticated } from '../../UserManagement/hook/Authenticated'

import {
  HomeIcon,
  SavedIcon,
  CartIcon,
  ProfileIcon,
  SignOutIcon,
} from "./icon";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const NavigationBar = ({isEditing}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
	const [showPopUp, setShowPopUp] = useState(false)

  const [profileUrl, setProfileUrl] = useState('/login')
	const [navigationTarget, setNavigationTarget] = useState('/')

  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };
    
	const cookie_value = Cookies.get('kangacook_user_id')
	if (cookie_value){
	  const username = JSON.parse(cookie_value).username || ''
	  setProfileUrl(`/profile/${username.replace(/ /g, "%20")}/`)
	}

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
			{
			showPopUp &&
			<div className='popup-overlay'>
					<div className='popup-content' onClick={(e) => e.stopPropagation()}>
							<h2> {t("navigation.leavingTitle")}</h2>
							<p> {t("navigation.leavingMsg")}</p>
							<div className='popup-buttons'>
									<button className='back-to-recipe-button' onClick={() => setShowPopUp(false)}>
											{t("navigation.cancel")}
									</button>
									<button className='view-cart-button' onClick={() => navigate(navigationTarget)}>
											{t("navigation.continue")}
									</button>
							</div>
					</div>
			</div>
			}
      <nav
        className={`navigation-bar ${isDesktop ? "desktop-nav" : "mobile-nav"}`}
      >
        <div className={`nav-container ${isDesktop ? "nav-top" : ""}`}>
          <NavigationItem
            icon={HomeIcon}
						title='home-icon'
            onClick={(e) => {
              e.preventDefault();
							if(isEditing){
								setShowPopUp(true)
								setNavigationTarget('/')
							}
							else{
								navigate(`/`)
							}
            }}
            isActive={currentPath === "/"}
          >
            {t("navigation.home")}
          </NavigationItem>
          <NavigationItem
            icon={SavedIcon}
			title='save-icon'
            onClick={(e) => {
              e.preventDefault();
							if(isAuthenticated()){
								if(isEditing){
									setShowPopUp(true)
									setNavigationTarget(`${profileUrl}?section=saved`)
								}
								else{
									navigate(`${profileUrl}?section=saved`)
								}
							}
							else{
								if(isEditing){
									setShowPopUp(true)
									setNavigationTarget(`/login`)
								}
								else{
									navigate(`/login`)
								}
							}
            }}
            isActive={
            window.location.pathname === profileUrl && window.location.search === "?section=saved"
            }
          >
            {t("navigation.saved")}
          </NavigationItem>
          <NavigationItem
            icon={CartIcon}
			title='cart-icon'
            onClick={(e) => {
              e.preventDefault();
							if(isAuthenticated()){
								if(isEditing){
									setShowPopUp(true)
									setNavigationTarget(`/shopping-cart`)
								}
								else{
									navigate(`/shopping-cart`)
								}
							}
							else{
								if(isEditing){
									setShowPopUp(true)
									setNavigationTarget(`/login`)
								}
								else{
									navigate(`/login`)
								}
							}
            }}
            isActive={currentPath === "/shopping-cart"}
          >
            {t("navigation.cart")}
          </NavigationItem>
          <NavigationItem
            icon={ProfileIcon}
			title='profile-icon'
            onClick={(e) => {
              e.preventDefault();
							if(isEditing){
								setShowPopUp(true)
								setNavigationTarget(`${profileUrl}?section=mine`)
							}
							else{
								navigate(`${profileUrl}?section=mine`, { replace: true });
							}
            }}
            isActive={
              window.location.pathname === profileUrl && window.location.search === "?section=mine" 
            }
          >
            {t("navigation.profile")}
          </NavigationItem>
        </div>

        <div
          className="nav-container nav-bottom"
          style={isDesktop ? {} : { display: "none" }}
        >
          { isAuthenticated() &&
          <NavigationItem icon={SignOutIcon} path="/home" onClick={()=>Cookies.remove('kangacook_user_id', { domain: process.env.REACT_APP_COOKIE_URL })}>
            {t("navigation.logOut")}

          </NavigationItem>
          }
          <div className="nav-item disabled">
            <Divider />
          </div>

          <FooterLinks />
          <div className="nav-item disabled">
            <Divider />
          </div>
          <SocialLinks />
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
