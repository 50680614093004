import './Ingredients.css'
import up_img from '../icons/chevron-up.svg'
import '../../components/components.css'
import IngredientCheckbox from './components/IngredientCheckbox'
import cart_img from '../icons/cart.svg'
import {useState, useEffect} from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import {isAuthenticated} from '../../UserManagement/hook/Authenticated';
import {useNavigate} from 'react-router-dom';

export default function Ingredients({recipe_id, serving_count, recipe_ingredients}){
	const navigate = useNavigate();
	const ingredients = recipe_ingredients? recipe_ingredients.map(ingredient => `${ingredient.serving} ${ingredient.name}`) : []
	const [showPopup, setShowPopup] = useState(false); 

	const ingredients_id = recipe_ingredients? recipe_ingredients.map(ingredients => ingredients.id) : []
	const [boughtIngredientID, setBoughtIngredientID] = useState(new Set());
	const [ingredientBoughtCount, setIngredientBoughtCount] = useState(0);

	useEffect(() => {
		const fetchBoughtIngredients = async () => {
			if(!isAuthenticated()){
				return;
			}
			try{
				const apiHost = process.env.REACT_APP_BACKEND_URL;
				const response = await fetch(`${apiHost}/recipe/${recipe_id}/cart`, {
					method: "GET",
					credentials: "include",
				});
				const data = await response.json();
				setBoughtIngredientID(new Set(data.map(item=>item.id)))
			}
			catch (error) {
				console.error('Error fetching user collection:', error);
			}
		}
		fetchBoughtIngredients()
	}, [recipe_id])
	// 0 = not bought yet
	// 1 = selected
	// 2 = bought
	const [ingredientsState, setIngredientsState] = useState(Array(ingredients.length).fill(0))
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();

	/*
	Reset ingredients state when ingredients are updated from the parent component. 
	The reason is that the parent component has async call to fetch ingredients, 
	so the ingredientsState is not updated immediately.
	*/
	useEffect(() => {
		setIngredientsState(Array(ingredients.length).fill(0));
	}, [ingredients.length]);
	
	
	useEffect(() => {
		if(boughtIngredientID.size > 0 && ingredients.length > 0){
			const newStates = ingredientsState
			for(let i = 0; i < newStates.length; i++){
				if(boughtIngredientID.has(ingredients_id[i])){
					newStates[i] = 2;
				}
			}
			setIngredientsState(newStates);
		}
	}, [boughtIngredientID, ingredients_id])

	const selectAll = ()=>{
		let newState = ingredientsState;
		for(let i = 0; i < newState.length; i++){
			if(newState[i] === 0){
				newState[i] = 1;
			}
		}
		setIngredientsState([...newState])
	}
	const unselectAll = ()=>{
		let newState = ingredientsState;
		for(let i = 0; i < newState.length; i++){
			if(newState[i] === 1){
				newState[i] = 0;
			}
		}
		setIngredientsState([...newState])
	}

	const handleAddToCart = async () => {
		try {
			if(!isAuthenticated()){
				navigate('/login')
				return;
			}
			const selectedIngredients = recipe_ingredients
				.filter((_, index) => ingredientsState[index] === 1)
				.map(ingredient => ({
					ingredient_id: ingredient.id,
					quantity: 1,
				}));

			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/cart-ingredient/`, { ingredients: selectedIngredients }, { withCredentials: true });
			const newStates = ingredientsState
			let newIngredients = 0;
			for(let i = 0; i < newStates.length; i++){
				if(newStates[i] === 1){
					newStates[i] = 2;
					newIngredients++;
				}
			}
			setIngredientsState([...newStates])
			setShowPopup(true); 
			setIngredientBoughtCount(newIngredients)
			return response.data;
		} catch (error) {
			console.error('Add to cart failed:', error);
			throw error;
		}
	};

	const closePopup = () => {
		setShowPopup(false); 
	};

	return (
        <div className='recipe-page-ingredients'>
            {/* 弹窗 */}
            {showPopup && (
                <div className='popup-overlay' onClick={closePopup}>
                    <div className='popup-content' onClick={(e) => e.stopPropagation()}>
												<h2>
													{t("recipePage.addedToCart",
														{count: ingredientBoughtCount})}
												</h2>
                        <p>{t("recipePage.addedToCartDescription")}</p>
                        <div className='popup-buttons'>
                            <button onClick={closePopup} className='back-to-recipe-button'>
															{t("recipePage.backToRecipe")}
                            </button>
                            <button onClick={() => navigate('/shopping-cart')} className='view-cart-button'>
                                <img src="/shoppingcard.png" alt="cart icon" className="cart-icon" />
																{t("recipePage.viewCart")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            
            <div className='recipe-page-ingredients-header'>
                <div className='recipe-page-ingredients-header-header'>
                    <p className='recipe-page-section-title'>
                        {t("recipePage.ingredients")}
                    </p>
                    <div className='recipe-page-collapse' onClick={() => setCollapse(!collapse)}>
                        {collapse ? (
                            <img style={{ transform: 'rotate(180deg)' }} src={up_img} alt='collapse-icon' />
                        ) : (
                            <img src={up_img} alt='collapse-icon' />
                        )}
                    </div>
                </div>
                <p className='recipe-page-ingredients-header-serving-count'>
                    ({serving_count} {serving_count > 1 ? t("recipePage.servings") : t("recipePage.serving")})
                </p>
                {!collapse && (
                    <div className='recipe-page-ingredients-selections'>
                        {ingredientsState.every(v => v > 0) ? (
                            <div className='s-prime-tertiary-text-container-disabled recipe-page-ingredients-selectAll' onClick={selectAll}>
                                <p className='prime-tertiary-text-disabled'> {t('recipePage.selectAll')} </p>
                            </div>
                        ) : (
                            <div className='s-prime-tertiary-text-container recipe-page-ingredients-selectAll' onClick={selectAll}>
                                <p className='prime-tertiary-text'> {t('recipePage.selectAll')} </p>
                            </div>
                        )}
                        {ingredientsState.every(v => v !== 1) ? (
                            <div className='s-prime-tertiary-text-container-disabled recipe-page-ingredients-unselectAll' onClick={unselectAll}>
                                <p className='prime-tertiary-text-disabled'>{t('recipePage.unselectAll')}</p>
                            </div>
                        ) : (
                            <div className='s-prime-tertiary-text-container recipe-page-ingredients-unselectAll' onClick={unselectAll}>
                                <p className='prime-tertiary-text'>{t('recipePage.unselectAll')}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        
            {!collapse && (
                <>
                    <div className='recipe-page-ingredients-container'>
                        {ingredients.map((item, index) => (
                            <IngredientCheckbox
                                name={item}
                                states={ingredientsState}
                                setStates={setIngredientsState}
                                index={index}
                                key={index}
                            />
                        ))}
                    </div>
        
                    {ingredientsState.every(v => v !== 1) ? (
                        <div className='recipe-page-ingredients-cart'>
                            <div className='secondary-prime-wrapper-disabled'>
                                <img src={cart_img} className='secondary-prime-icon-disabled' alt="cart icon" />
                                <p className='secondary-prime-text-disabled'> {t('recipePage.addToCart')} </p>
                            </div>
                        </div>
                    ) : (
                        <div className='recipe-page-ingredients-cart'>
                            <div className='secondary-prime-wrapper' onClick={handleAddToCart}>
                                <img src={cart_img} className='secondary-prime-icon' alt="cart icon" />
                                <p className='secondary-prime-text'> {t('recipePage.addToCart')} </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
