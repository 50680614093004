import React from "react";
import PointsProgressBar from "../components/PointsProgressBar/PointsProgressBar";
import PointsHistory from "../components/PointsHistory/PointsHistory";
import EarnRedeem from "../components/EarnRedeem/EarnRedeem";
import Header from "../../components/Header";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RewardPage.module.css";
import { ScrollProvider } from "../ScrollContext";

export default function RewardPage() {
  const [currPoints, setCurrPoints] = useState(0);
  const [initialLanguage, setInitialLanguage] = useState("");
	const [checkinToggle, setCheckinToggle] = useState(false)
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!initialLanguage) {
      setInitialLanguage(i18n.language);
    } else if (i18n.language !== initialLanguage) {
      window.location.reload();
    }
  }, [i18n.language, initialLanguage]);
  useEffect(() => {
    const getPoints = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/reward/points`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        if (response.ok) {
          const data = await response.json();
					setCurrPoints(data.point__sum || 0);
        } else {
          setCurrPoints(0);
          console.error("Failed to fetch draft recipe:", response.statusText);
        }
      } catch (error) {
        setCurrPoints(0);
        console.error("Error fetching draft recipe:", error);
      }
    };
		getPoints()
  }, [checkinToggle]);
  return (
    <>
      <Header />
      <NavigationBar />
      <div className={styles["reward-page-container"]}>
        <div className={styles["reward-page"]}>
          <ScrollProvider>
            {/* <div className={styles["reward-page-container"]}> */}
            <PointsProgressBar currentPoints={currPoints} />
            <EarnRedeem checkinToggle={checkinToggle} setCheckinToggle={setCheckinToggle} currentPoints={currPoints}/>
            <PointsHistory checkinToggle={checkinToggle}/>
            {/* </div> */}
          </ScrollProvider>
        </div>
      </div>
    </>
  );
}
