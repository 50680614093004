// src/authServices.jsx
import { CognitoIdentityProviderClient, SignUpCommand, ConfirmSignUpCommand, InitiateAuthCommand, ForgotPasswordCommand, ConfirmForgotPasswordCommand, ResendConfirmationCodeCommand } from "@aws-sdk/client-cognito-identity-provider";
import Cookies from "js-cookie";
import axios from 'axios';

// Initialize the Cognito Identity Provider client
const client = new CognitoIdentityProviderClient({ region: process.env.REACT_APP_AWS_REGION });

// Sign up function
export const resendConfirmationCode = async (email) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    Username: email,
  };

  try {
    const command = new ResendConfirmationCodeCommand(params);
    const response = await client.send(command);
    console.log('resendConfirmationCode:', response);
    return response;
  } catch (error) {
    console.log('Error Resemd Confirmation:', error);
    throw error;
  }
};

// Confirm forget passwrod 
export const confirmForgotPassword = async (email, confirmationCode, password) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    Username: email,
    ConfirmationCode: confirmationCode,
    Password: password,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await client.send(command);
    // console.log('Confirm Forgot Password Successful:', response);
    return response;
  } catch (error) {
    console.log('Error confirmForgotPassword:', error);
    throw error;
  }
};

// Sign up function
export const signUp = async (password, email, username, invitor) => {
  const cookie_lang = Cookies.get("lang") || "en";
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    Username: email,
    Password: password,
    UserAttributes: [
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: "nickname",
        Value: username,
      },
			{
				Name: 'custom:language',
				Value: cookie_lang,
			},
			{
				Name: 'custom:invitor',
				Value: invitor,
			},
    ],
  };

  try {
    const command = new SignUpCommand(params);
    const response = await client.send(command);
    console.log('Sign up successful:', response);
    return response;
  } catch (error) {
		// If email had already been registered, check if email is unconfirmed: 
		// This section is required since otherwise if user left email confirmation page during registration, 
		// 		then the email used will never be able to be used to create account or confirm email address in the future.
    const oauthUrl = `${process.env.REACT_APP_BACKEND_URL}/user-pool/is-unconfirmed/?email=${email}`
    try{
			const response = await axios.get(oauthUrl, 
				{withCredentials: true},
			);
			if(response.data.unconfirmed){
				// if email is not confirmed, return and to go email confirmation page.
				return;
			}
			// if email is confirmed / not exist, throw error and not redirect to confirmation page.
    } catch (error) {
			console.error("Error in GoogleOauth: ", error)
			throw error;
    }
    throw error;
  }
};

// Confirm sign up function
export const confirmSignUp = async (email, code) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    Username: email,
    ConfirmationCode: code,
  };

  try {
    const command = new ConfirmSignUpCommand(params);
    const response = await client.send(command);
    console.log('Confirmation successful:', response);
    return response;
  } catch (error) {
    console.log('Error confirming sign up:', error);
    throw error;
  }
};

// Sign in function
export const signIn = async (username, password) => {
  const params = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await client.send(command);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};


// Confirm sign up function
export const forgotPassword = async (email) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    Username: email
  };

  try {
    console.log('Sending forgot password request:', params); // 添加日志
    const command = new ForgotPasswordCommand(params);
    const response = await client.send(command);
    console.log('Forgot password request successful:', response);
    return response;
  } catch (error) {
    console.log('Error sending forgot password request:', error);
    throw error;
  }
};
