import RedeemGuideCard from "./RedeemGuideCard";
import TempImage from "./card.png";

export default function RedeemPointsGuide({ currentPoints }) {
  return (
    <>
      <RedeemGuideCard currentPoints={currentPoints} title="??" img={TempImage} />
    </>
  );
}
