import React from "react";
import "./Dish.css";
import defaultImage from "../icons/RecipeCard.jpeg";
import deleteIcon from "../icons/DeleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import {useState} from 'react'

export default function Dish({ description, title, cover_picture, recipe_id, removeDish}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
	const [isConfirming, setIsConfirming] = useState(false)
	const deleteRecipe = (e) => {
		e.stopPropagation();
		setIsConfirming(true)
	}
	const confirmDeleteRecipe = async (e) => {
		e.stopPropagation();
		try {
			const apiHost = process.env.REACT_APP_BACKEND_URL;
			await fetch(`${apiHost}/cart-ingredient/${recipe_id}/delete-by-recipe`, {
				method: "DELETE",
				credentials: "include",
			});
			removeDish(recipe_id)
		} catch (error) {
			console.error("Error Deleting recipe ingredients: ", error);
		}
		setIsConfirming(false)
	}
  return (
    <div
      className="cart-dish-component"
      onClick={() => navigate(`/recipe/${recipe_id}`)}
    >
		{isConfirming && (
			<div className="cart-dish-delete-confirm" onClick={(e) => e.stopPropagation()}>
				<div className="cart-dish-delete-confirm-card">
					<p className="cart-dish-delete-confirm-text">
						{t("cart.removeRecipe")}
					</p>
					<div className="cart-dish-delete-confirm-buttons">
						<div
							className="secondary-text-wrapper"
							onClick={(e) => {setIsConfirming(false); e.stopPropagation()}}
						>
							<p className="secondary-text">{t("cart.cancel")}</p>
						</div>
						<div
							className="primary-text-wrapper"
							onClick={confirmDeleteRecipe}
						>
							<p className="primary-text">{t("cart.confirm")}</p>
						</div>
					</div>
				</div>
			</div>
		)}
      <div className="cart-dish-image-container">
        <img src={cover_picture || defaultImage} alt="recipe-image" />
      </div>
      <div className="cart-dish-text-container">
        <p className="cart-dish-title">{title ? title : "Recipe Title"}</p>
        <p className="cart-dish-supporting-text">
          {description ? description : t("cart.supportingText")}
        </p>
      </div>
      <div className="cart-dish-delete-icon-container">
        <img src={deleteIcon} alt="delete-icon" onClick={deleteRecipe}/>
      </div>
    </div>
  );
}
