import {Helmet} from 'react-helmet'
import MyRecipes from './ProfileHome/Recipes/MyRecipes/MyRecipes'
import './UserProfileContent.css'
import {useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import {useParams, useNavigate} from 'react-router-dom'
import {isAuthenticated} from '../../UserManagement/hook/Authenticated'
import {NotFound} from '../../components/ErrorPage'
import default_profile from '../components/ProfileHome/icons/Avatar.svg'
import { useTranslation } from "react-i18next";

export default function PublicUserProfileContent({changeLang, initialUserInfo}){
	const [fetchingRecipe, setFetchingRecipe] = useState(false)
	const navigate = useNavigate();

	const LATEST = 'latest'
	const POPULAR = 'popular'
	const EARLIEST = 'earliest'
	const { t } = useTranslation();

	const cookie_value = Cookies.get('kangacook_user_id')
	const curr_username = (cookie_value && JSON.parse(cookie_value).username) || '';
	const [notFound, setNotFound] = useState(false)
	const {username} = useParams();
	const [userIcon, setUserIcon] = useState(default_profile)
	const [userInfo, setUserInfo] = useState(initialUserInfo || {
		username: 'unknown',
		profile_picture: null,
		bio: 'empty bio',
		email: 'no email',
		first_name: '',
		last_name: '',
		birthday: '',
	})
	useEffect(() => {
		userInfo.profile_picture && setUserIcon(userInfo.profile_picture)
	}, [userInfo.profile_picture])

	const fetchUserInfo = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}`);
			if(response.status === 404){
				setNotFound(true)
				return
			}
			const data = await response.json();
			setUserInfo(data); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user information:', error);
		}
	}
	useEffect(() => {
		fetchUserInfo()
		fetchUserStats()
		fetchFollowing()
	}, [])
	const [following, setFollowing] = useState(false)
	const [userStats, setUserStats] = useState({
		recipes: 0,
		followers: 0,
		followings: 0,
	})
	const [userCollections, setUserCollections] = useState(null)

	const [latest, setLatest] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=latest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=latest`]
	})
	const [popular, setPopular] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=popular`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=popular`]
	})
	const [earliest, setEarliest] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=earliest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=earliest`]
	})

	const [recipes, setRecipes] = useState(latest)
	const [selected, setSelected] = useState(LATEST)

	const [toggleCollection, setToggleCollection] = useState(false)
	const fetchUserStats = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/stats`,{
				method: 'GET',
			});
			const data = await response.json();
			setUserStats(data); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user followers:', error);
		}
	}
	const fetchUserCollections = async () => {
		if (!isAuthenticated()){
			return
		}
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			setUserCollections(data.filter(collection => collection.name !== 'Saved')); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user collection:', error);
		}
	}

	const unfollow = async () => {
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/${username}/`,{
				method: 'DELETE',
				credentials: 'include',
			});
		} catch (error) {
			console.error('Error unfollowing user', error);
		}
		fetchFollowing()
	}
	const follow = async () => {
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/`,{
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					following: username,
				})
			});
		} catch (error) {
			console.error('Error following user', error);
		}
		fetchFollowing()
	}
	const toggleFollow = async() => {
		if(curr_username === ''){
			navigate(`/login`)
			return 
		}
		if(following){
			setFollowing(false)
			unfollow()
		}
		else{
			setFollowing(true)
			follow()
		}
	}
	const fetchFollowing = async () => {
		if (!isAuthenticated()){
			return
		}
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-network/${username}/is_following/?username=${curr_username}`
			)
			if(response.status === 200){
				setFollowing(true)
			}
			else{
				setFollowing(false)
			}
		}
		catch(error){
			console.log("THIS IS ERROR")
			console.error("ERROR FETCHING IF USER IS FOLLOWING. ", error)
		}
	}


	useEffect(() => {
		fetchUserCollections()
	}, [toggleCollection])

	useEffect(() => {
		const scrollFunc = async () => {
			if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
				if(!fetchingRecipe){
					if(selected === LATEST){
						if(latest.next){
							fetchLatest()
							setFetchingRecipe(true)
						}
					}
					else if(selected === POPULAR){
						if(popular.next){
							fetchPopular()
							setFetchingRecipe(true)
						}
					}
					else if(selected === EARLIEST){
						if(earliest.next){
							fetchEarliest()
							setFetchingRecipe(true)
						}
					}
					else{
						console.log("SOME TING WONG")
					}
				}
			}
		}
		window.addEventListener('scroll', scrollFunc)
		return ()=>{
			window.removeEventListener('scroll', scrollFunc)
		}
	}, [selected, fetchingRecipe])
	const fetchLatest = async () => {
		try {
		  console.log(latest)
		  console.log(latest.next)
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${latest.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!latest.history.includes(data.next)){
				latest.history.push(data.next)
				latest.next = data.next
				latest.results = latest.results.concat(data.results)
				setLatest(({...latest})); 
			}
		} catch (error) {
			console.error('Error fetching user information:', error);
		}
		setFetchingRecipe(false)
	}

	const fetchPopular = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${popular.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!popular.history.includes(data.next)){
				popular.history.push(data.next)
				popular.next = data.next
				popular.results = popular.results.concat(data.results)
				setPopular(({...popular})); 
			}
		} catch (error) {
			console.error('Error fetching user recipe (order: popular):', error);
		}
		setFetchingRecipe(false)
	}
	const fetchEarliest = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${earliest.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!earliest.history.includes(data.next)){
				earliest.history.push(data.next)
				earliest.next = data.next
				earliest.results = earliest.results.concat(data.results)
				setEarliest(({...earliest})); 
			}
		} catch (error) {
			console.error('Error fetching user recipe (order: popular):', error);
		}
		setFetchingRecipe(false)
	}
	useEffect(() => {
		switch (selected){
			case LATEST:
				setRecipes(latest)
				return 
			case POPULAR:
				setRecipes(popular)
				return 
			case EARLIEST:
				setRecipes(earliest)
				return 
			default:
				return null
		}
	}, [selected, latest, earliest, popular])


	const resetRecipes = () => {
	  latest.next = `/user-profile/${username}/my-recipes/?sort=latest`
	  latest.results = []
	  latest.history = [`/user-profile/${username}/my-recipes/?sort=latest`]
	  setLatest({
		next:`/user-profile/${username}/my-recipes/?sort=latest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=latest`]
	  })
	  popular.next = `/user-profile/${username}/my-recipes/?sort=popular`
	  popular.results = []
	  popular.history = [`/user-profile/${username}/my-recipes/?sort=popular`]
	  setPopular({
		next:`/user-profile/${username}/my-recipes/?sort=popular`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=popular`]
	  })
	  earliest.next = `/user-profile/${username}/my-recipes/?sort=earliest`
	  earliest.results = []
	  earliest.history = [`/user-profile/${username}/my-recipes/?sort=earliest`]
	  setEarliest({
		next:`/user-profile/${username}/my-recipes/?sort=earliest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=earliest`]
	  })
	}
	useEffect(()=>{
		resetRecipes()
		fetchUserInfo()
		fetchLatest()
		fetchPopular()
		fetchEarliest()
	},[changeLang, username])
	return (
		<>
		<Helmet>
			<meta property="og:title" content={username ? username : 'Profile'} />
			<meta property="og:description" content={userInfo && userInfo.bio ? userInfo.bio : t("publicUserProfileContent.noDescription")} />
			<meta property="og:image" content={userIcon || default_profile} />
			<meta property="og:url" content={`https://kangacook.com/profile/${username}`} />
			<meta property="og:type" content="profile" />

			<meta name="description" content={userInfo && userInfo.bio ? userInfo.bio : t("publicUserProfileContent.noDescription")} />
			<title> {username ? username : 'Loading...'} </title>
			<link rel="canonical" href={`https://kangacook.com/profile/${username}`} />
		</Helmet>
		{
		notFound?
		<NotFound /> : 
		<div className='user-profile-content'>
			{
				<span>
					<div className='user-profile-user-description'>
						<div className='user-profile-detailed-description'>
							<div className='user-profile-avatar-container'>
								<span>
									<div
										className='x-large-avatar-container'
									>
										<img src={userIcon} alt='user-icon' className='x-large-avatar-avatar' />
										<div className='x-large-avatar-cover'>
										</div>
									</div>
								</span>
							</div>
							<div className='user-profile-stats'>
								<div className='user-profile-stats-count'>
									{userStats.recipes}
								</div>
								<div className='user-profile-stats-name'>
									{userStats.recipes > 1? t("publicUserProfileContent.recipes") : t("publicUserProfileContent.recipe")}
								</div>
							</div>
							<div className='user-profile-stats '>
								<div className='user-profile-stats-count'>
									{userStats.followers}
								</div>
								<div className='user-profile-stats-name'>
									{userStats.followers > 1? t("publicUserProfileContent.followers") : t("publicUserProfileContent.follower")}
								</div>
							</div>
							<div className='user-profile-stats '>
								<div className='user-profile-stats-count'>
									{userStats.followings}
								</div>
								<div className='user-profile-stats-name'>
									{userStats.followings > 1? t("publicUserProfileContent.followings") : t("publicUserProfileContent.following")}
								</div>
							</div>
						</div>
						<h1 className='user-profile-user-name'>
							{username}
						</h1>
						<div className='user-profile-user-bio'>
							<p> {userInfo.bio} </p>
						</div>

						<div className='user-profile-user-options'>
						{
							following? 
							<div
								className='secondary-text-wrapper'
								onClick={toggleFollow}
							>
								<p className='secondary-text '>
									{t("publicUserProfileContent.following")}
								</p>
							</div>
							:
							<div
								className='secondary-prime-wrapper'
								onClick={toggleFollow}
							>
								<p className='secondary-prime-text'>
									{t("publicUserProfileContent.follow")}
								</p>
							</div>
						}
							<div
								className='secondary-text-wrapper'
								onClick={() => navigator.clipboard.writeText(window.location.href)}
							>
								<p className='secondary-text '>
									{t("publicUserProfileContent.share")} 
								</p>
							</div>
						</div>
					</div>

					<div className='user-profile-recipes'>
						<div className='user-profile-recipes-selection-container'>
							<div className='user-profile-recipes-selection'>
								<div 
									className={
											'tab-button-active-container'
										}
								>
									<p 
										className={
												'tab-button-active-text'
										}
									>
										{t("publicUserProfileContent.recipes")} 
									</p>
								</div>
							</div>
						</div>
				
						{
							<MyRecipes
								collections={userCollections}
								toggleCollection={toggleCollection}
								setToggleCollection={setToggleCollection}
								recipes={recipes}
								selected={selected}
								setSelected={setSelected}
								owner={false}
							/>
						}
					</div>
				</span>
			}
		</div>
		}
		</>
	)
}


