import React from "react";
import { Button } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { loginGoogleOauth } from "../../hook/oauthService";
import "./GoogleOauth.css";
import { useLocation } from "react-router-dom";

export default function GoogleOauthButton_Signup() {
  const navigate = useNavigate();
  const onFailure = (error) => {
    console.error("Google login failed:", error);
  };
  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const invite_id = queryParams.get("invitor");

  const onSuccess = async (response) => {
    // get accessToken fron response
    const accessToken = response.access_token;
    const userInfo = await loginGoogleOauth(accessToken, invite_id);
    //save userId to session
    Cookies.set("kangacook_user_id", JSON.stringify(userInfo), {
      expires: 90,
      domain: process.env.REACT_APP_COOKIE_URL,
    });
    //redirect to homepage
    navigate('/');
  };

  // const onError = (error) => {
  //     console.log(error);
  // };

  const login = useGoogleLogin({
    onSuccess,
    onFailure,
  });

  // Modify text content
  return (
    <div>
      <Button
        type="primary"
        onClick={() => login()}
        className="signup-google-button"
      >
        <img src="/google.png" alt="Google Icon" className="google-icon" />
        Signup with Google
      </Button>
    </div>
  );
}
