import UserProfileContent from './components/UserProfileContent'
import PublicUserProfileContent from './components/PublicUserProfileContent'
import './UserProfile.css'

import Header from '../components/Header'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import {useParams} from 'react-router-dom'
import Cookies from 'js-cookie'
import {useState, useEffect} from 'react'

import {isAuthenticated} from '../UserManagement/hook/Authenticated'

export default function UserProfile(){
	const [changeLang, setChangeLang] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [userInfo, setUserInfo] = useState(null)
	const {username} = useParams();
	const cookie_value = Cookies.get('kangacook_user_id')
	const current_username = (cookie_value && JSON.parse(cookie_value).username) || ''
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(false)

	// Add function to fetch user info
	const fetchUserInfo = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}`);
			if (response.status === 404) {
				setIsLoading(false)
				return
			}
			const data = await response.json();
			setUserInfo(data)
			setIsLoading(false)
		} catch (error) {
			console.error('Error fetching user information:', error);
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const initializeProfile = async () => {
			const authenticated = isAuthenticated()
			setIsUserAuthenticated(authenticated)
			if (username) {
				await fetchUserInfo()
			} else {
				setIsLoading(false)
			}
		}
		initializeProfile()
	}, [username])

	if (isLoading || !userInfo) {
		return <div className='user-profile'>
			<Header changeLang={changeLang} setChangeLang={setChangeLang} />
			<NavigationBar />
			<div className='user-main-content'>
				{/* Optional: Add loading spinner here */}
			</div>
		</div>
	}

	return (
		<div className='user-profile'>
			<Header changeLang={changeLang} setChangeLang={setChangeLang} />
			<NavigationBar />
			<div className='user-main-content'>
				{
					isUserAuthenticated && username === current_username ?
					<UserProfileContent 
						changeLang={changeLang} 
						initialUserInfo={userInfo} // Pass the initial user info
					/>
					:
					<PublicUserProfileContent 
						changeLang={changeLang} 
						initialUserInfo={userInfo} // Pass the initial user info
					/>
				}
			</div>
		</div>
	)
}

