import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Header from '../components/Header';
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './RewardShippingCard.css';
import AlertIcon from '../components/icons/recipeCreate/alert.svg';
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../UserManagement/hook/Authenticated";

export default function RewardShippingCard() {
  const { t } = useTranslation();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '44px',
      paddingLeft: '0', // 确保内边距在 placeholder 中处理
      borderRadius: '8px',
      border: errors.state
        ? '1px solid #F87171' // 錯誤時顯示紅色邊框
        : '1px solid #D0D5DD',
      fontSize: '16px',
      boxSizing: 'border-box',
      outline: 'none',
      backgroundColor: '#FFFFFF',
      appearance: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '6px', 
      fontSize: '16px',
      color: '#98A2B3', // 确保 placeholder 的颜色与其他输入框一致
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '8px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
  };
  const stateOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ];

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      address: '',
      specificAddress: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      phone: '',
  });  

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleStateChange = (selectedOption) => {
      setFormData({ ...formData, state: selectedOption ? selectedOption.value : '' });
  };

  const handleNext = () => {
    if (validateForm()) {
      navigate('/confirm-rewardshippingcard', { state: formData });
    }  
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = t("rewardShippingCard.firstNameIsRequired");
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = t("rewardShippingCard.lastNameIsRequired");
    }

    if (!formData.address.trim()) {
      newErrors.address = t("rewardShippingCard.addressIsRequired");
    }

    if (!formData.city.trim()) {
      newErrors.city = t("rewardShippingCard.cityIsRequired");
    }

    if (!formData.state) {
      newErrors.state = t("rewardShippingCard.stateIsRequired");
    }

    if (!formData.zip) {
      newErrors.zip = t("rewardShippingCard.zipCodeIsRequired");
    } else if (
      !formData.zip.toString().trim() ||
      isNaN(formData.zip) ||
      parseInt(formData.zip) <= 0
    ) {
      newErrors.zip = t("rewardShippingCard.zipCodeMustBeAPositiveNumber");
    }

    if (!formData.email.trim()) {
      newErrors.email = t("rewardShippingCard.emailIsRequired");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("rewardShippingCard.invalidEmailFormat");
    }

    if (!formData.phone.trim()) {
      newErrors.phone = t("rewardShippingCard.phoneNumberIsRequired");
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = t("rewardShippingCard.phoneNumberMustBe10Digits");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // 如果無錯誤，回傳 true
  }

  return (
    <>
      <Header/>
      <NavigationBar/>
      <div className='rewardshippingcard-page'>
        <div className='rewardshippingcard-container'>
          <h4 className='shipping-info-title'>{t("rewardShippingCard.shippingInformation")}</h4>
          <div className='name-inputs'>
            <div className='input-group'>
              <label htmlFor='first-name'>{t("rewardShippingCard.firstName")}</label>
              <input 
                type='text' 
                id='firstName'
                value={formData.firstName}
                onChange={handleInputChange} 
                style={{
                  borderColor: errors.firstName ? '#F87171' : '#D0D5DD',
                  outline: errors.firstName ? 'none' : 'none',
                }}
              />
              {errors.firstName && (
                <div className="flex items-center mt-1 text-red-500 text-sm">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p>{errors.firstName}</p>
                </div>
              )}
            </div>
            <div className='input-group'>
              <label htmlFor='last-name'>{t("rewardShippingCard.lastName")}</label>
              <input 
                type='text' 
                id='lastName' 
                value={formData.lastName}
                onChange={handleInputChange}
                style={{
                  borderColor: errors.lastName ? '#F87171' : '#D0D5DD',
                  outline: errors.lastName ? 'none' : 'none',
                }}
              />
              {errors.lastName && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.lastName}</p>
                </div>
              )}
            </div>
          </div>
          <div className='address-input-group'>
            <label htmlFor='shipping-address'>{t("rewardShippingCard.shippingAddress")}</label>
            <input 
              type='text' 
              id='address' 
              placeholder={t("rewardShippingCard.streetAddressOrPOBox")}
              value={formData.address}
              onChange={handleInputChange}
              style={{
                borderColor: errors.address ? '#F87171' : '#D0D5DD',
                outline: errors.address ? 'none' : 'none',
              }}
            />
            {errors.address && (
              <div className="flex">
                <img src={AlertIcon} alt="Alert" className="mr-2" />
                <p className="text-red-500 text-sm">{errors.address}</p>
              </div>
            )}
          </div>
          <div className='specific-address-input-group'>
            <input 
              type='text' 
              id='specificAddress' 
              placeholder={t("rewardShippingCard.aptSuiteUnitBuildingFloorEtc")} 
              value={formData.specificAddress}
              onChange={handleInputChange}
            />
          </div>
          <div className='city-state-container'>
            <div className='city-container'>
              <input 
                type='text' 
                id='city' 
                placeholder={t("rewardShippingCard.city")}  
                className='city-input'
                value={formData.city}
                onChange={handleInputChange}
                style={{
                  borderColor: errors.city ? '#F87171' : '#D0D5DD',
                  outline: errors.city ? 'none' : 'none',
                }}
              />
              {errors.city && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.city}</p>
                </div>
              )}
            </div>
            <div className='state-container'>
              <Select 
                options={stateOptions} 
                styles={customStyles}
                placeholder={t("rewardShippingCard.state")} 
                onChange={handleStateChange}
                value={formData.state ? { label: stateOptions.find(option => option.value === formData.state)?.label, value: formData.state } : null}
              />
              {errors.state && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.state}</p>
                </div>
              )}
            </div>
          </div>
          <div className='country-zip-inputs'>
            <div className='email-input-group'>
              <input 
                type='text' 
                id='country' 
                placeholder='United States' 
                disabled 
                style={{ backgroundColor: '#F9FAFB'}} 
              />
            </div>
            <div className='email-input-group'>
              <input 
                type='text' 
                id='zip' 
                placeholder={t("rewardShippingCard.zipCode")} 
                value={formData.zip}
                onChange={handleInputChange}
                style={{
                  borderColor: errors.zip ? '#F87171' : '#D0D5DD',
                  outline: errors.zip ? 'none' : 'none',
                }}
              />
              {errors.zip && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.zip}</p>
                </div>
              )}
            </div>
          </div>
          <div className='email-inputs'>
            <div className='email-input-group'>
              <label htmlFor='email'>{t("rewardShippingCard.email")}</label>
              <input 
                type='text' 
                id='email' 
                value={formData.email}
                onChange={handleInputChange}
                style={{
                  borderColor: errors.email ? '#F87171' : '#D0D5DD',
                  outline: errors.email ? 'none' : 'none',
                }}
              />
              {errors.email && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.email}</p>
                </div>
              )}
            </div>
            <div className='email-input-group'>
              <label htmlFor='Phone-Number'>{t("rewardShippingCard.phoneNumber")}</label>
              <input 
                type='text' 
                id='phone' 
                value={formData.phone}
                onChange={handleInputChange}
                style={{
                  borderColor: errors.phone ? '#F87171' : '#D0D5DD',
                  outline: errors.phone ? 'none' : 'none',
                }}
              />
              {errors.phone && (
                <div className="flex">
                  <img src={AlertIcon} alt="Alert" className="mr-2" />
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                </div>
              )}
            </div>
          </div>
          <div className='button-group'>
            <button className='cancel-button' onClick={() => navigate("/reward")}>
              {t("rewardShippingCard.cancel")}
            </button>
            <button
              className='next-button'
              onClick={() => {
                if (!isAuthenticated()) {
                  navigate('/login');
                  return;
                }
                handleNext();
              }}
            >
              {t("rewardShippingCard.next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
