import React from "react";
import styles from "./EarnPointsGuide.module.css";
import SendIcon from "../../../components/icons/send.png";
import LinkIcon from "../../../components/icons/link.png";
import AlertIcon from "./alert-octagon.svg";
import CheckIcon from "./check-circle.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { isAuthenticated } from "../../../UserManagement/hook/Authenticated";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const EarnPointsGuide = ({ isCheckedIn, checkinToggle, setCheckinToggle }) => {
    const { t } = useTranslation();
    const [pointsGuide, setPointsGuide] = useState([
        {
            id: 0,
            points: 50,
            description: t("pointsGuide.inviteFriends"),
            action: "Copy invite link",
            earned: false,
            campaign: 100,
        },
        {
            id: 21,
            points: 20,
            description: t("pointsGuide.post2Recipes"),
            earned: false,
            campaign: 50,
        },
        {
            id: 1,
            points: 20,
            description: t("pointsGuide.post10Recipes"),
            earned: false,
            campaign: 150,
        },
        {
            id: 2,
            points: 30,
            description: t("pointsGuide.post25Recipes"),
            earned: false,
        },
        {
            id: 3,
            points: 20,
            description: t("pointsGuide.friendsPost5Recipes"),
            earned: false,
            type: "friend posting reward",
        },
        {
            id: 4,
            points: 30,
            description: t("pointsGuide.get100Likes"),
            earned: false,
        },
        {
            id: 5,
            points: 2,
            description: t("pointsGuide.signInEveryDay"),
            action: "Check-in today",
            earned: false,
        },
        {
            id: 23,
            points: 2,
            description: t("pointsGuide.checkIn7Days"),
            earned: false,
            campaign: 50,
        },
        {
            id: 6,
            points: 3,
            description: t("pointsGuide.postRecipeEveryDay"),
            earned: false,
        },
    ]);
    const [toastMessage, setToastMessage] = useState("");
    const [invitedFriendCount, setInvitedFriendCount] = useState(0);
    const [friendRewardCount, setFriendRewardCount] = useState(0);
    const cookie_value = Cookies.get("kangacook_user_id");
    const userId = (cookie_value && JSON.parse(cookie_value).userId) || "";
    const navigate = useNavigate();
    useEffect(() => {
        if (isCheckedIn) {
            updatePointsGuideIfChanged(setPointsGuide, 5, true);
        }
    }, [isCheckedIn]);
    
	// Helper function to update states only if necessary
	const updatePointsGuideIfChanged = (setPointsGuide, id, newEarnedValue) => {
		setPointsGuide((prevGuide) => {
			const itemToUpdate = prevGuide.find(item => item.id === id);
			// Only update if the earned value is different
			if (itemToUpdate && itemToUpdate.earned !== newEarnedValue) {
				return prevGuide.map((item) =>
					item.id === id
						? { ...item, earned: newEarnedValue }
						: item
				);
			}
			// Return the previous state unchanged if no update is needed
			return prevGuide;
		});
	};

    const checkIn = async (e) => {
        e.preventDefault();
        if (!isAuthenticated()) {
            navigate("/login");
            console.log("YOU NEED TO LOGIN FIRST");
            return;
        }
        try {
            const apiHost = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(`${apiHost}/reward/sign-in/`, {
                method: "POST",
                credentials: "include",
            });
            if (response.ok) {
                setToastMessage(t("reward.checkInSuccessfully"));
                setTimeout(() => setToastMessage(""), 2000);
                setPointsGuide((prevGuide) => 
                    prevGuide.map((item) =>
                        item.id === 5 ? { ...item, earned: true } : item
                    )
                );
                setCheckinToggle(!checkinToggle);
            }
        } catch (error) {
            console.error("Error checking in:", error);
        }

        return;
    };
	
    useEffect(() => {

		const check7Day = async () => {
			if (!isAuthenticated()) {
				console.log("YOU NEED TO LOGIN FIRST");
				return;
			}
			try {
				const apiHost = process.env.REACT_APP_BACKEND_URL;
				const response = await fetch(`${apiHost}/reward/check-in-7-day/`, {
					method: "GET",
					credentials: "include",
				});
				if (response.ok) {
					const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 23, data["7 day"]);
				}
			} catch (error) {
				console.error("Error checking 7 day check in reward:", error);
			}
	
			return;
		};

        const checkPosted2 = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/posted-2-check/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 21, data["posted 2"]);
                }
            } catch (error) {
                console.error("Error checking posting 10 reward:", error);
            }

            return;
        };
        const checkPosted10 = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/posted-10-check/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 1, data["posted 10"])
                }
            } catch (error) {
                console.error("Error checking posting 10 reward:", error);
            }

            return;
        };
        const checkPosted25 = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/posted-25-check/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 2, data["posted 25"])
                }
            } catch (error) {
                console.error("Error checking posting 25 reward:", error);
            }

            return;
        };
        const checkLikesReward = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/100-total-likes/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 4, data["100 likes"])
                }
            } catch (error) {
                console.error("Error checking 100 likes reward:", error);
            }

            return;
        };
        const checkDaily = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/daily-posting-check/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
					updatePointsGuideIfChanged(setPointsGuide, 6, data.posted)
                }
            } catch (error) {
                console.error("Error checking daily posting reward:", error);
            }
        };
        const checkFriendInvitation = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/invited-friend-count/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    const moreThanFive = data["invited friends"] >= 5;
					updatePointsGuideIfChanged(setPointsGuide, 0, moreThanFive)
                    setInvitedFriendCount(data["invited friends"]);
                }
            } catch (error) {
                console.error(
                    "Error checking friend invitation reward:",
                    error
                );
            }

            return;
        };
        const checkFriendReward = async () => {
            if (!isAuthenticated()) {
                console.log("YOU NEED TO LOGIN FIRST");
                return;
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/reward/friends-posted-count/`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setFriendRewardCount(data["friends reward"]);
                }
            } catch (error) {
                console.error("Error checking friends posting reward:", error);
            }

            return;
        };
        checkDaily();
        check7Day();
        checkPosted2();
        checkPosted10();
        checkPosted25();
        checkLikesReward();
        checkFriendInvitation();
        checkFriendReward();
    }, [checkinToggle]);

    const [userRef, setUserRef] = useState(null);
    useEffect(() => {
        const cookie_value = Cookies.get("kangacook_user_id");
        const username =
            (cookie_value && JSON.parse(cookie_value).username) || "";

        const getUserRef = async () => {
            if (!username) {
                console.error("User needs to login first");
            }
            try {
                const apiHost = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(
                    `${apiHost}/user-identity/${username}/ref-id`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUserRef(data.ref_id);
                }
            } catch (error) {
                console.error("Error getting user's refference id:", error);
            }
        };
        getUserRef();
    }, []);

    const copyInviteLink = () => {
        if (!isAuthenticated()) {
            console.log("YOU NEED TO LOGIN FIRST");
            return;
        }
        if (!userRef) {
            console.error("Something went wrong, please try again");
            return;
        }
        const inviteLink = `${window.location.origin}/signup/?invitor=${userRef}`;
        navigator.clipboard.writeText(inviteLink).then(
            () => {
                setToastMessage(t("reward.inviteLinkCopied"));
                setTimeout(() => setToastMessage(""), 2000);
            },
            (err) => {
                console.error("Error copying invite link:", err);
            }
        );
    };
    return (
        <div className={styles.earnPointsContainer}>
            {toastMessage && (
                <div className={styles.toastContainer}>
                    <div className={styles.toast}>
                        <img src={CheckIcon} alt="success icon" />
                        <p> {toastMessage} </p>
                    </div>
                </div>
            )}

            <h2 className={styles.title}>{t("reward.howToEarnPoints")}</h2>
            <ul className={styles.pointsList}>
                {pointsGuide.map((item, index) =>
                    item.earned ? (
                        <li
                            key={index}
                            className={[
                                styles.pointsItemEarned,
                                styles.pointsItem,
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    styles.pointsBadge,
                                    styles.pointsBadgeEarned,
                                ].join(" ")}
                            >
                                <>
                                    <div
                                        className={[
                                            styles.pointsBadgeDigits,
                                            styles.pointsBadgeDigitsEarned,
                                        ].join(" ")}
                                    >
                                        {item.campaign
                                            ? item.campaign
                                            : item.points}{" "}
                                    </div>
                                    <span
                                        className={[
                                            styles.desktop,
                                            styles.desktopEarned,
                                        ].join(" ")}
                                    >
                                        {" "}
                                        {t("reward.points")}
                                    </span>
                                    <span
                                        className={[
                                            styles.mobile,
                                            styles.mobileEarned,
                                        ].join(" ")}
                                    >
                                        {" "}
                                        {t("reward.pts")}
                                    </span>
                                </>
                            </div>
                            <span className={styles.description}>
                                {item.description}
                            </span>
                            {item.action &&
                                ((item.action === "Copy invite link" && (
                                    <>
                                        <img
                                            src={CheckIcon}
                                            alt="success icon"
                                        />
                                        <p className={styles.earned}>
                                            {" "}
                                            {invitedFriendCount} / 5{" "}
                                        </p>
                                        <button
                                            className={styles.actionButton}
                                            onClick={() => {
                                                copyInviteLink();
                                            }}
                                        >
                                            <span className={styles.sendIcon}>
                                                <img
                                                    src={SendIcon}
                                                    alt="send-icon"
                                                />
                                            </span>
                                            <div
                                                className={`${styles.desktop} ${styles.buttonDescription}`}
                                            >
                                                {t(
                                                    "pointsGuide.copyInviteLink"
                                                )}
                                            </div>
                                        </button>
                                    </>
                                )) || (
                                    <>
                                        <img
                                            src={CheckIcon}
                                            alt="success icon"
                                        />
                                        <p className={styles.earned}>
                                            {" "}
                                            {t("reward.earned")}{" "}
                                        </p>
                                    </>
                                ))}
                        </li>
                    ) : (
                        <li key={index} className={styles.pointsItem}>
                            <div
                                className={
                                    item.campaign
                                        ? [
                                              styles.pointsBadge,
                                              styles.pointsBadgeCampaign,
                                          ].join(" ")
                                        : styles.pointsBadge
                                }
                            >
                                <>
                                    <div className={styles.pointsBadgeDigits}>
                                        {item.campaign
                                            ? item.campaign
                                            : item.points}{" "}
                                    </div>
                                    <span className={styles.desktop}>
                                        {" "}
                                        {t("reward.points")}
                                    </span>
                                    <span className={styles.mobile}>
                                        {" "}
                                        {t("reward.pts")}
                                    </span>
                                </>
                            </div>
                            <span className={styles.description}>
                                {item.description}
                            </span>
                            {item.action &&
                                (item.action === "Copy invite link" ? (
                                    <>
                                        <p className={styles.earned}>
                                            {" "}
                                            {invitedFriendCount} / 5{" "}
                                        </p>
                                        <button
                                            className={styles.actionButton}
                                            onClick={() => {
                                                copyInviteLink();
                                            }}
                                        >
                                            <span className={styles.sendIcon}>
                                                <img
                                                    src={SendIcon}
                                                    alt="send-icon"
                                                />
                                            </span>
                                            <div
                                                className={`${styles.desktop} ${styles.buttonDescription}`}
                                            >
                                                {t(
                                                    "pointsGuide.copyInviteLink"
                                                )}
                                            </div>
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className={styles.actionButton}
                                        onClick={checkIn}
                                    >
                                        <span className={styles.linkIcon}>
                                            <img
                                                src={LinkIcon}
                                                alt="link-icon"
                                            />
                                        </span>
                                        <div
                                            className={`${styles.desktop} ${styles.buttonDescription}`}
                                        >
                                            {t("pointsGuide.checkInToday")}
                                        </div>
                                    </button>
                                ))}
                            {item.type &&
                                item.type === "friend posting reward" && (
                                    <p className={styles.earned}>
                                        {" "}
                                        {friendRewardCount} /{" "}
                                        {invitedFriendCount}{" "}
                                    </p>
                                )}
                        </li>
                    )
                )}
            </ul>
        </div>
    );
};

export default EarnPointsGuide;
