import React, { useState } from 'react';
import {Helmet} from 'react-helmet'
import Select from 'react-select';
import Header from '../components/Header';
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './ContactUsCard.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";



const customStyles = {
    control: (provided) => ({
        ...provided,
        width: '100%',
        height: '44px',
        paddingLeft: '16px',
        borderRadius: '8px',
        border: '1px solid #D0D5DD',
        fontSize: '16px',
        boxSizing: 'border-box',
        outline: 'none',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
    }),
    placeholder: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        lineHeight: '44px',
        fontSize: '16px',
        color: '#98A2B3',
        transform: 'translateY(-4px)',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '16px',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '8px',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '16px',
        transform: 'translateY(-2px)',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        paddingRight: '16px',
        color: '#D0D5DD',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};

export default function ContactUsCard() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const options = [
        { value: 'help', label: t("contactUsCard.categoryUserSupport") },
        { value: 'partner', label: t("contactUsCard.categoryPartner") },
        { value: 'advertising', label: t("contactUsCard.categoryAdvertising") },
        { value: 'security', label: t("contactUsCard.categorySecurity") },
        { value: 'privacy', label: t("contactUsCard.categoryPrivacy") },
        { value: 'others', label: t("contactUsCard.categoryOthers") }
    ];
    // 表单状态
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        summary: '',
        category: null,
        content: '',
    });

    // 错误状态
    const [errors, setErrors] = useState({});

    // 加载状态
    const [isLoading, setIsLoading] = useState(false);

    // 处理输入改变
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    // 处理选择框的改变
    const handleSelectChange = (selectedOption) => {
        setFormData({
            ...formData,
            category: selectedOption,
        });
    };

    // 表单验证
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email){
            newErrors.email = 'Email is required';
        }
        else{
            const reg = /^(?!\.)(?!.*\.\.)[a-zA-Z0-9\._%+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/
            if (!reg.test(formData.email)){
                newErrors.email = 'Invalid Email Address';
            }
        }
        if (!formData.summary) newErrors.summary = 'Summary is required';
        if (!formData.category) newErrors.category = 'Category is required';
        if (!formData.content) newErrors.content = 'Content is required';
        return newErrors;
    };

    // 提交处理
    const handleSubmit = async () => {
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setIsLoading(true);  // 显示加载动画
            const script_url = "https://script.google.com/macros/s/AKfycbybVTGVERvigAowk5mCujFKNwSJQ_pW7vpy1nBOcPFoZe_Y5Vo0LJm7y5PNG1ml9lyEjQ/exec"
            try {
                await fetch(script_url, {
                    headers: {
                        'Content-Type': 'application/json' // Set the header to JSON
                    },
                    mode: 'no-cors',
                    redirect: "follow",
                    method: 'POST',
                    body: JSON.stringify(formData),
                });
                setIsLoading(false);  // 提交完成后隐藏加载动画
                navigate("/confirm-contactus") 
            } catch (error) {
                setIsLoading(false);  // 错误时也设置加载状态为 false
                setErrors({ submit: 'There was an error processing your request. Please try again.' });
            }
        }
    };

    return (
        <div className='home-page'>
			<Helmet>
                <meta property="og:title" content='Kangacook - Contact Us'/>
                <meta property="og:description" content= "Let us know if you have any questions or suggestions, to help us improve your experience with our site!"/>
                <meta property="og:url" content="https://kangacook.com/contactus/"/>
                <meta property="og:type" content="website" />

				<meta name="description" content="Let us know if you have any questions or suggestions, to help us improve your experience with our site!"/>
				<title> Kangacook - Contact Us </title>
                <link rel="canonical" href="https://kangacook.com/contactus" />
            </Helmet>
            <Header />
            <div className='home-page-body'>
                <div className='home-page-nav-bar'> Navigation </div>
                <NavigationBar />
                <div className='contact-page'>
                    <div className='contact-container'>
                        <h4 className='contact-title'>{t("contactUsCard.contactUs")}</h4>
                        <div className='name-inputs'>
                            <div className='input-group'>
                                <label htmlFor='name'>{t("contactUsCard.name")}</label>
                                <input type='text' id='name' value={formData.name} onChange={handleChange} />
                                {errors.name && <span className="contactus-error-message">{errors.name}</span>}
                            </div>
                            <div className='input-group'>
                                <label htmlFor='email'>{t("contactUsCard.email")}</label>
                                <input type='text' id='email' value={formData.email} onChange={handleChange} />
                                {errors.email && <span className="contactus-error-message">{errors.email}</span>}
                            </div>
                        </div>
                        <div className='summary-input-group'>
                            <label htmlFor='summary'>{t("contactUsCard.summary")}</label>
                            <input type='text' id='summary' value={formData.summary} onChange={handleChange} />
                            {errors.summary && <span className="contactus-error-message">{errors.summary}</span>}
                        </div>
                        <div className='summary-input-group'>
                            <label htmlFor='category'>{t("contactUsCard.category")}</label>
                            <div className='category-container'>
                                <Select 
                                    options={options} 
                                    styles={customStyles}
                                    value={formData.category}
                                    onChange={handleSelectChange}
                                    placeholder={t("contactUsCard.categoryPlaceholder")}
                                />
                                {errors.category && <span className="contactus-error-message">{errors.category}</span>}
                            </div>
                        </div>
                        <div className='bigger-summary-input-group'>
                            <label htmlFor='content'>{t("contactUsCard.content")}</label>
                            <textarea 
                                id='content' 
                                value={formData.content}
                                onChange={handleChange}
                                placeholder={t("contactUsCard.contentPlaceholder")}
                            />
                            {errors.content && <span className="contactus-error-message">{errors.content}</span>}
                        </div>
                        <div className='contact-button-group'>
                            <button type="button" className='contact-next-button' onClick={handleSubmit}>
                                {t("contactUsCard.submitButton")}
                            </button>
                        </div>
                    </div>
                </div>
                {/* 加载动画模态层 */}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
