import styles from "./Tab.module.css";

const TabButton = ({ tabName, onClick, isCurrent }) => {
  return (
    <div
      onClick={onClick}
      className={`${styles["tab-button"]} ${
        isCurrent ? styles["current"] : styles["not-current"]
      }`}
    >
      <div className={styles["tab-name"]}>{tabName}</div>
    </div>
  );
};

export default TabButton;
