import "./Checkout.css";
import "../../components/components.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Checkout({ selectedStore }) {
  const { t } = useTranslation();
  const [isConfirming, setIsConfirming] = useState(false);
	const link = {
		"fudiAsianMart": "https://www.fantuanorder.com/en-US/store/Restaurant/us-1725834561?rTraceId=whole_search-1-0-1856040975147732992&shippingType=1",
		"walmart": "https://www.walmart.com/",
	}
  return (
    <>
      <div className="cart-checkout">
        <div
          onClick={() => setIsConfirming(true)}
          className="primary-text-wrapper cart-checkout-continue"
        >
          <p className="primary-text">{t("cart.continue")}</p>
        </div>
      </div>
      {isConfirming && (
        <div className="cart-checkout-confirm">
          <div className="cart-checkout-confirm-card">
            <p className="cart-checkout-confirm-text">
              {t("cart.directTo")} {t(`cart.${selectedStore}`)}{" "}
              {t("cart.shoppingCart")}
            </p>
            <div className="cart-checkout-confirm-buttons">
              <div
                className="secondary-text-wrapper"
                onClick={() => setIsConfirming(false)}
              >
                <p className="secondary-text">{t("cart.cancel")}</p>
              </div>
              <a
								href={link[selectedStore]}
								rel="noreferrer"
								target="_blank"
                className="primary-text-wrapper"
                onClick={() => setIsConfirming(false)}
              >
                <p className="primary-text">{t("cart.confirm")}</p>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
